const scriptUrl = `https://maps.googleapis.com/maps/api/js?key=AIzaSyCIleSovGX7QVEVoSvId_K7eWFXqsW5iCM&libraries=places`

let scriptPromise = null

export const loadGoogleMapsScript = async () => {
  if (!scriptPromise) {
    scriptPromise = new Promise((resolve, reject) => {
      if (window.google && window.google.maps) {
        resolve()
        return
      }

      const script = document.createElement("script")
      script.src = scriptUrl
      script.async = true // Ensures the script is loaded asynchronously
      script.defer = true // Ensures the script is executed after the document has been parsed
      script.onload = () => {
        resolve()
      }
      script.onerror = () => {
        reject(new Error("Failed to load Google Maps script"))
      }
      document.head.appendChild(script)
    })
  }

  return scriptPromise
}
