import { create } from "@mui/material/styles/createTransitions"
import { createContext, useState, useMemo } from "react"

export const DriverContext = createContext(null)

function DriverContextProvider(props) {
  const [rowData, setRowData] = useState([])

  const contextValue = useMemo(
    () => ({
      rowData,
      setRowData,
    }),
    [ rowData]
  )

  return (
    <DriverContext.Provider value={contextValue}>
      {props.children}
    </DriverContext.Provider>
  )
}

export default DriverContextProvider
