import React from "react"
import {
  Stack,
  TextField,
  Button,
  Checkbox,
  Typography,
  Link,
  useMediaQuery,
  useTheme,
  Box,
} from "@mui/material"
import TermsPopup from "../../../../popups/Terms"
import LocationField from "../../../../components/LocationField"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar"
import { TimeClock } from "@mui/x-date-pickers/TimeClock"
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker"
import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"
import timezone from "dayjs/plugin/timezone"

// Enable plugins

import Grid from "@mui/material/Grid"

dayjs.extend(utc)
dayjs.extend(timezone)
const today = dayjs()
const twoPM = dayjs().set("hour", 14).startOf("hour")
const threePM = dayjs().set("hour", 15).startOf("hour")
const TIMEZONE_OFFSET = 5.5

const getMinPickupTime = () => {
  const now = new Date()
  now.setMinutes(now.getMinutes() + 15)
  return now.toISOString().slice(0, 16) // Return in 'YYYY-MM-DDTHH:MM' format
}

const ReservationForm = ({
  pickupValue,
  setPickupValue,
  dropValue,
  setDropValue,
  formValues,
  setFormValues,
  errors,
  checkboxChecked,
  setCheckboxChecked,
  handleReservation,
  openPopups,
  handleOpenPopup,
  handleClosePopup,
  width,
}) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))
  const [value, setValue] = React.useState(dayjs("2022-04-17T15:30"))

  const [minPickupTime, setMinPickupTime] = React.useState(getMinPickupTime())

  const getMinMaxPickupTimes = () => {
    const now = new Date()
    let minTime = new Date()
    let maxTimeToday = new Date()
    let maxTimeNextDay = new Date()

    // Set minTime to 15 minutes from the current time
    now.setMinutes(now.getMinutes() + 15)

    // For today's minTime, it needs to be at least 6:00 p.m., or 15 minutes ahead if current time is after 6:00 p.m.
    if (now.getHours() < 18) {
      minTime.setHours(18, 0, 0, 0) // Set to 6:00 p.m.
    } else if (now.getHours() >= 18) {
      minTime = now // If it's already past 6:00 p.m., use current time + 15 minutes.
    }

    // Set maxTimeToday to 11:59 p.m. (end of the day)
    maxTimeToday.setHours(23, 59, 0, 0)

    // Set maxTimeNextDay to 2:00 a.m. for tomorrow
    maxTimeNextDay.setDate(maxTimeNextDay.getDate() + 1) // Move to the next day
    maxTimeNextDay.setHours(2, 0, 0, 0)

    return {
      minTime: minTime.toISOString().slice(0, 16),
      maxTimeToday: maxTimeToday.toISOString().slice(0, 16),
      maxTimeNextDay: maxTimeNextDay.toISOString().slice(0, 16),
    }
  }

  const [pickupTimeRange, setPickupTimeRange] = React.useState(
    getMinMaxPickupTimes()
  )

  React.useEffect(() => {
    // Update min and max time every minute in case the component stays open
    const interval = setInterval(() => {
      setPickupTimeRange(getMinMaxPickupTimes())
    }, 60000) // Update every 60 seconds

    return () => clearInterval(interval) // Cleanup on unmount
  }, [])

  React.useEffect(() => {
    // Update minPickupTime every minute in case the component stays open
    const interval = setInterval(() => {
      setMinPickupTime(getMinPickupTime())
    }, 60000) // Update every 60 seconds

    return () => clearInterval(interval) // Cleanup on unmount
  }, [])

 const [isLoading, setIsLoading] = React.useState(false)

 const handleButtonClick = async () => {
   setIsLoading(true) // Disable the button
   try {
     await handleReservation() // Call the reservation handler
   } catch (error) {
     console.error("API call failed:", error)
   } finally {
     setIsLoading(false) // Re-enable the button
   }
 }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: "#fff",
        color: "#000",
        // borderRadius: "8px",
        maxHeight: "800px",
        overflowY: "auto",
        padding: "20px",
      }}
    >
      <Stack direction="column" spacing={2} width="100%">
        {/* Stack for form fields */}
        <Stack
          direction={isMobile ? "column" : "row"}
          spacing={1}
          justifyContent={isMobile ? "center" : "space-between"}
          alignItems={isMobile ? "stretch" : "center"}
        >
          <Stack spacing={2} width={isMobile ? "100%" : "48%"}>
            <TextField
              // fullWidth
              label="Name"
              variant="outlined"
              margin="normal"
              value={formValues.name}
              onChange={(e) =>
                setFormValues({ ...formValues, name: e.target.value })
              }
              error={errors.name}
              helperText={errors.name ? "Name is required" : ""}
              width={width}
              sx={{ backgroundColor: "white" }}
            />
            <TextField
              // fullWidth
              label="Email Address (Optional)"
              variant="outlined"
              margin="normal"
              value={formValues.email}
              onChange={(e) =>
                setFormValues({ ...formValues, email: e.target.value })
              }
              // error={errors.email}
              // helperText={errors.email ? "Invalid email address" : ""}
              width={width}
            />
            <TextField
              // fullWidth
              label="Contact Number"
              variant="outlined"
              margin="normal"
              value={formValues.phone}
              onChange={(e) =>
                setFormValues({ ...formValues, phone: e.target.value })
              }
              error={errors.phone}
              helperText={errors.phone ? "Invalid phone number" : ""}
              width={width}
            />
            <TextField
              // fullWidth
              label="Promo Code (Optional)"
              variant="outlined"
              margin="normal"
              value={formValues.promoCode}
              onChange={(e) =>
                setFormValues({ ...formValues, promoCode: e.target.value })
              }
              width={width}
            />
          </Stack>

          <Stack spacing={2} width={isMobile ? "100%" : "48%"}>
            <LocationField
              value={pickupValue}
              setValue={setPickupValue}
              label="Pickup Location"
              error={errors.pickup}
              helperText={errors.pickup ? "Pickup Location is required" : ""}
              width={width}
            />
            <LocationField
              value={dropValue}
              setValue={setDropValue}
              label="Drop Location"
              error={errors.drop}
              helperText={errors.drop ? "Drop Location is required" : ""}
              width={width}
            />

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                closeOnSelect={false}
                value={formValues.pickup_time || null}
                onChange={(newValue) => {
                  console.log(newValue)
                  console.log(errors)
                  setFormValues({ ...formValues, pickup_time: newValue })
                }}
                disablePast // Prevent selecting past dates and times
                minDateTime={dayjs().add(15, "minute")} // Ensure pickup time is at least 15 minutes from now
                views={["year", "month", "day", "hours", "minutes"]}
                shouldDisableTime={(value, view) => {
                  if (view === "hours") {
                    // Disable hours from 3:00 a.m. to 5:00 p.m.
                    return value.hour() < 18 && value.hour() > 2 // Allow only hours from 6:00 p.m. to 2:00 a.m.
                  }
                  return false // Allow all minutes
                }}
                slotProps={{
                  textField: {
                    helperText: errors.pickup_time
                      ? "Pickup time must be between 6:00 p.m. and 2:00 a.m."
                      : "",
                      error:Boolean(errors.pickup_time) // String
                  },
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                   
                  />
                )}
              />
            </LocalizationProvider>
            <Stack direction={"row"} alignItems={"center"}>
              <Checkbox
                checked={checkboxChecked}
                onChange={(e) => setCheckboxChecked(e.target.checked)}
                sx={{ marginRight: "8px" }} // Reduce spacing to the right of checkbox
              />
              <Typography color={"black"} align="left">
                I have read and understand the{" "}
                <Link onClick={() => handleOpenPopup("About Us")}>
                  terms and conditions
                </Link>
              </Typography>
            </Stack>
            {errors.checkbox && (
              <Typography
                color="red"
                variant="caption"
                sx={{ marginTop: "4px" }}
              >
                You must agree to the terms and conditions
              </Typography>
            )}
          </Stack>
        </Stack>
        <Button
          fullWidth
          variant="contained"
          sx={{
            marginTop: "20px",
            backgroundColor: isLoading ? "#ccc" : "#FFCF43",
            "&:hover": {
              backgroundColor: isLoading ? "#ccc" : "#FFD8A3",
            },
          }}
          onClick={handleButtonClick}
          disabled={isLoading}
        >
          {isLoading ? "Processing..." : "Make Reservation"}
        </Button>
        <TermsPopup
          open={openPopups["About Us"]}
          onClose={() => handleClosePopup("About Us")}
          name={"About Us"}
        />
      </Stack>
    </Box>
  )
}

export default ReservationForm
