import React, { useContext } from "react"
import EnhancedTable from "./PaymentTable"
import api from "../../../apiConfig"
import { PaymentContext } from "../../../context/PaymentContext"
import dayjs from "dayjs"

const formatDate = (date) => {
  return date.toISOString().split("T")[0] // Converts to "YYYY-MM-DD"
}

const toDateTimeObject = (dateStr, timeStr = "00:00:00") => {
  return new Date(`${dateStr}T${timeStr}`)
}

const Payment = () => {

  const today = new Date()
  const tomorrow = new Date(today)
  tomorrow.setDate(today.getDate() + 1)

  const [startDate, setStartDate] = React.useState(
    formatDate(new Date("2020-01-01T00:00:00"))
  )
  const [endDate, setEndDate] = React.useState(formatDate(tomorrow)) // Current date
  const [count, setCount] = React.useState(0)
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const { setRowData } = useContext(PaymentContext)

  React.useEffect(() => {
    api
      .get("/api/v1/companychargesettlement/list", {
        params: {
          page: page,
          size: rowsPerPage,
          startDate: dayjs(toDateTimeObject(startDate))
            .tz("Asia/Colombo")
            .format("YYYY-MM-DDTHH:mm:ss"),
          endDate: dayjs(toDateTimeObject(endDate, "23:59:59"))
            .tz("Asia/Colombo")
            .format("YYYY-MM-DDTHH:mm:ss"),
        },
      })
      .then((response) => {
        if (response.data.status === 200) {
          console.log("Payments fetched!", response.data.data.datalist)
          setCount(response.data.data.count)
          setRowData(
            response.data.data.datalist.map((payment) =>
              createPaymentData(
                payment.propertyId,
                payment.driver,
                payment.amount,
                payment.date,
                payment.note
              )
            )
          )
        } else {
          console.log("something went wrong!", response.data.data.dataList)
        }
      })
  }, [rowsPerPage, setRowData, page, startDate, endDate])

  function createPaymentData(id, driver_id, amount_lkr, paid_at, notes) {
    return { id, driver_id, amount_lkr, paid_at, notes }
  }

  return (
    <EnhancedTable
      count={count}
      page={page}
      setPage={setPage}
      rowsPerPage={rowsPerPage}
      setRowsPerPage={setRowsPerPage}
      startDate={startDate}
      setStartDate={setStartDate}
      endDate={endDate}
      setEndDate={setEndDate}
    />
  )
}

export default Payment
