// Client.js
import React, { useEffect } from "react"
import { useNavigate, Outlet } from "react-router-dom"
import {
  Typography,
  Stack,
  ButtonGroup,
  Button,
  AppBar,
  Box,
  Toolbar,
  IconButton,
} from "@mui/material"
import CurrentJob from "./CurrentJob"
import AssignedJobs from "./AssignedJobs"
import { AuthContext } from "../../context/AuthContext"
import { Navigate } from "react-router-dom"
import api from "../../apiConfig"
import Cookies from "js-cookie"
import { wait } from "@testing-library/user-event/dist/cjs/utils/index.js"
import { LogoutRounded } from "@mui/icons-material"

const Client = () => {
  const [selectedOption, setSelectedOption] = React.useState("current")
  const [currentTime, setCurrentTime] = React.useState(new Date())
  const navigate = useNavigate()
  const [assignedJobs, setAssignedJobs] = React.useState([])
  const { user, username, userType, login, logout } =
    React.useContext(AuthContext)

  const handleButtonClick = (option) => {
    setSelectedOption(option)
  }

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date())
    }, 1000)

    return () => clearInterval(timer)
  }, [])

  const assignedJobsData = [
    {
      id: 1,
      pickupLocation: "Location A",
      pickupTime: "2024-08-16T10:30:00Z",
    },
    {
      id: 2,
      pickupLocation: "Location B",
      pickupTime: "2024-08-16T14:00:00Z",
    },
  ]

  useEffect(() => {
    console.log("User type:", userType)
    console.log("user", username)
    if (userType === "driver") {
      api
        .get(`/api/v1/driver/driver-trips`, {
          params: { phone: username, page: 0, size: 5 },
        })
        .then((response) => {
          if (response.data.status === 200) {
            console.log("Assigned jobs:", response.data.data.dataList)
            if (response.data.data.dataList.length === 0) {
              console.log("No trips found")
              return
            }
            setAssignedJobs(
              response.data.data.dataList.map((prop, index) => ({
                id: prop.propertyId,
                pickupLocation: prop.pickupLocation,
                pickupTime: new Date(prop.pickupTime).toLocaleString(),
                clientName: prop.clientName,
                clientPhone: prop.clientPhone,
                status: prop.status,
                waitingStartTime: prop.waitingStartTime,
                waitingTime: prop.waitingTime,
                startMeterReading: prop.startMeterReading,
                endMeterReading: prop.endMeterReading,
                tripEndTime: prop.tripEndTime,
                destination: prop.destination,
              }))
            )
          }
        })
    }
  }, [])

  const handleLogout = () => {
    // Handle logout logic here, like clearing tokens, etc.
    console.log("Logging out...")
    Cookies.remove("Authorization") // Remove the authToken cookie
    logout() // Call the logout function from the context
    delete api.defaults.headers.common["Authorization"]
    navigate("/wp-login") // Redirect to login page or any desired route
  }

  if (userType === "guest") {
    return <Navigate to="/wp-login" />
  }

  return (
    <Box
      sx={{
        minHeight: "100vh",
        // background: "linear-gradient(to bottom, #add8e6, #87ceeb)", // Light bluish gradient
      }}
    >
      <AppBar
        position="static"
        sx={{ backgroundColor: "#FFCF43", color: "black" }}
      >
        <Toolbar>
          <IconButton onClick={handleLogout}>
            <LogoutRounded />
          </IconButton>
          <Typography
            variant="h6"
            sx={{ color: "black", fontWeight: "bold", flexGrow: 1 }}
          >
            YouDrinkWeDrive
          </Typography>
        </Toolbar>
      </AppBar>
      <Stack>
        <Typography variant="h6" gutterBottom>
          Current Time: {currentTime.toLocaleTimeString()}
        </Typography>
        <ButtonGroup
          variant="contained"
          aria-label="Basic button group"
          color="grey"
          sx={{
            width: "100%",
            marginBottom: "20px",
            backgroundColor: "black",
          }}
        >
          <Button
            sx={{
              flex: 1,
              backgroundColor:
                selectedOption === "current" ? "#FFCF43" : "grey",
              color: selectedOption === "current" ? "black" : "white",
            }}
            onClick={() => handleButtonClick("current")}
          >
            Current Job
          </Button>
          <Button
            sx={{
              flex: 1,
              backgroundColor:
                selectedOption === "assigned" ? "#FFCF43" : "grey",
              color: selectedOption === "assigned" ? "black" : "white",
            }}
            onClick={() => handleButtonClick("assigned")}
          >
            Assigned Jobs
          </Button>
        </ButtonGroup>
        {selectedOption === "current" ? (
          <CurrentJob job={assignedJobs[0]} currentTime={currentTime} />
        ) : (
          <AssignedJobs jobs={assignedJobs} />
        )}
      </Stack>
    </Box>
  )
}

export default Client
