import * as React from "react"
import { useContext } from "react"
import { useNavigate, useLocation } from "react-router-dom"
import Box from "@mui/material/Box"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TablePagination from "@mui/material/TablePagination"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import Checkbox from "@mui/material/Checkbox"
import FormControlLabel from "@mui/material/FormControlLabel"
import Switch from "@mui/material/Switch"
import EnhancedTableToolbar from "./TableToolBar"
import EnhancedTableHead from "./TableHead"
import { TripContext } from "../../../context/TripContext"
import dayjs from "dayjs"

const formatDate = (date) => {
  return date.toISOString().split("T")[0] // Converts to "YYYY-MM-DD"
}

const toDateTimeObject = (dateStr, timeStr = "00:00:00") => {
  return new Date(`${dateStr}T${timeStr}`)
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) {
      return order
    }
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}

export default function EnhancedTable({
  count,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  status,
  setStatus,
}) {
  const location = useLocation()

  // Extract base route from the current location (either wp-admin or wp-manager)
  const baseRoute = location.pathname.includes("wp-admin")
    ? "wp-admin"
    : "wp-manager"

  const headCells = [
    {
      id: "id",
      type: "string",
      numeric: false,
      disabled: true,
      label: "Id",
    },
    {
      id: "published",
      type: "datetime",
      numeric: true,
      disabled: true,
      label: "Published",
    },
    {
      id: "pickup_location",
      type: "string",
      numeric: true,
      disabled: false,
      label: "Pickup Location",
    },
    {
      id: "driver",
      type: "autocomplete",
      numeric: true,
      disabled: false,
      label: "Driver",
      endpoint: "/api/v1/driver/list", // API endpoint for fetching driver options
    },
    {
      id: "pickup_time",
      type: "datetime",
      numeric: true,
      disabled: false,
      label: "Pickup Time",
    },
    {
      id: "destination",
      type: "string",
      numeric: true,
      disabled: false,
      label: "Destination",
    },
    {
      id: "trip_start_time",
      type: "datetime",
      numeric: true,
      disabled: true,
      label: "Trip Start Time",
    },
    {
      id: "trip_end_time",
      type: "datetime",
      numeric: true,
      disabled: true,
      label: "Trip End Time",
    },
    {
      id: "status",
      type: "enum",
      disabled: false,
      label: "Status",
      enum: [
        "CONFIRMED",
        "DRAFT",
        "CANCELLED",
        "STARTED",
        "WAITING_BEFORE",
        "WAITING_AFTER",
        "TRIPEND",
      ],
    },
    {
      id: "source",
      type: "enum",
      disabled: false,
      label: "Source",
      enum: [
        "WEB",
        "IOS",
        "ANDROID",
      ],
    },
  ]

  const { rowData } = useContext(TripContext)
  console.log("headCells", headCells)
  const [order, setOrder] = React.useState("desc")
  const [orderBy, setOrderBy] = React.useState(headCells[1].id)
  const [selected, setSelected] = React.useState([])
  const [dense, setDense] = React.useState(false)
  const navigate = useNavigate()

  console.log("Start Date", startDate)
  console.log("End Date", endDate)

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc"
    setOrder(isAsc ? "desc" : "asc")
    setOrderBy(property)
  }

  const handleClick = (event, id) => {
    event.stopPropagation()

    const selectedIndex = selected.indexOf(id)
    let newSelected = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      )
    }
    setSelected(newSelected)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleRowClick = (event, row) => {
    navigate(`/${baseRoute}/trip/post/${row.id}`)
  }

  const handleChangeDense = (event) => {
    setDense(event.target.checked)
  }

  const isSelected = (id) => selected.indexOf(id) !== -1

  const emptyRows = page > 0 ? Math.max(0, rowsPerPage - rowData.length) : 0

  const visibleRows = React.useMemo(
    () =>
      stableSort(rowData, getComparator(order, orderBy)).slice(0, rowsPerPage),
    [order, orderBy, rowsPerPage, rowData]
  )

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          title="Trips"
          headCells={headCells}
          startDate={startDate}
          endDate={endDate}
          status={status}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          setStatus={setStatus}
          onFilterChange={({ status, startDate, endDate }) => {
            console.log("Filter changed", { status, startDate, endDate })
            setStartDate(startDate)
            setEndDate(endDate)
            setStatus(status)
          }}
        />
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              // onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rowData.length}
              headCells={headCells}
            />
            <TableBody>
              {visibleRows.map((row, index) => {
                const isItemSelected = isSelected(row.id)
                const labelId = `enhanced-table-checkbox-${index}`

                return (
                  <TableRow
                    hover
                    onClick={(event) => handleRowClick(event, row)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                    selected={isItemSelected}
                    sx={{ cursor: "pointer" }}
                  >
                    {headCells.map((headCell) => (
                      <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? "right" : "left"}
                        sx={{ fontSize: "0.55rem" }}
                      >
                        {headCell.type === "datetime"
                          ? row[headCell.id]
                            ? // ? dayjs(row[headCell.id]).format("YYYY-MM-DD HH:mm:ss")
                              new Date(row[headCell.id])
                                .toLocaleString()
                                .slice(0, 19)
                                .replace("T", " ")
                            : ""
                          : row[headCell.id]}
                      </TableCell>
                    ))}
                  </TableRow>
                )
              })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  )
}
