import axios from "axios"
// import https from "https"

// Create a custom HTTPS agent that ignores SSL verification
// const agent = new https.Agent({
//   rejectUnauthorized: false, // Bypass SSL verification
// })

// Configure Axios to use the custom HTTPS agent
const api = axios.create({
  baseURL: "https://udvd.aivertica.online", // Replace with your actual API URL
  // baseURL: "http://localhost:8080", // Replace with your actual API URL
  // httpsAgent: agent,
})

export default api
