import * as React from "react"
import { useContext } from "react"
import { useParams, useNavigate } from "react-router-dom"
import Button from "@mui/material/Button"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableRow from "@mui/material/TableRow"
import TextField from "@mui/material/TextField"
import MenuItem from "@mui/material/MenuItem"
import Box from "@mui/material/Box"
import Toolbar from "@mui/material/Toolbar"
import Typography from "@mui/material/Typography"
import BackIcon from "@mui/icons-material/ArrowBack"
import AutocompleteField from "../Trip/AutoCompleteField"
import api from "../../../apiConfig"
import { PaymentContext } from "../../../context/PaymentContext"

export default function PaymentDialoguePage() {
  const navigate = useNavigate()
  const { id } = useParams()
  const [isEditing, setIsEditing] = React.useState(false)
  const { rowData } = useContext(PaymentContext)
  const row = rowData.find((row) => row.id === id)
  const [editedData, setEditedData] = React.useState(row)
  console.log("rowData", rowData)
  console.log("row", row)

  const dialogueHeadCells = [
    {
      id: "id",
      numeric: false,
      disablePadding: true,
      label: "ID",
      disabled: true,
    },
    {
      id: "driver_id",
      numeric: false,
      disablePadding: true,
      label: "Driver Name",
      disabled: true,
    },
    {
      id: "amount_lkr",
      numeric: true,
      disablePadding: false,
      label: "Amount (in LKR)",
      disabled: false,
    },
    {
      id: "paid_at",
      type: "datetime",
      numeric: false,
      disablePadding: false,
      label: "Paid at",
      disabled: false,
    },
    {
      id: "notes",
      numeric: false,
      disablePadding: false,
      label: "Notes",
      disabled: false,
    },
  ]

  const fieldLabels = dialogueHeadCells.reduce((acc, cell) => {
    acc[cell.id] = cell.label
    return acc
  })

  const handleEditClick = () => {
    setIsEditing(true)
  }

  const handleSaveClick = () => {
    console.log(dialogueHeadCells)
    setIsEditing(false)
    console.log("Edited Data", editedData)
    api
      .put(`/api/v1/companychargesettlement/${id}`, {
        ...editedData,
        propertyId: id,
      })
      .then((response) => {
        if (response.data.status === 200) {
          console.log("Data updated!", response.data)
          navigate(`/wp-admin/payment/post/${id}`)
        } else {
          console.log("something went wrong!", response.data)
        }
      })
  }

  const handleChange = (event) => {
    const { name, value } = event.target
    setEditedData({ ...editedData, [name]: value })
  }

  const formatDateTime = (dateString) => {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    }
    const date = new Date(dateString)
    return date.toLocaleString("en-US", options) // Adjust the locale if needed
  }

  const formatDateTimeLocal = (dateString) => {
    if (!dateString) return "" // Return empty string if no date is provided
    const date = new Date(dateString)
    // Format to YYYY-MM-DDTHH:MM
    return date.toISOString().slice(0, 16)
  }

  const renderInputField = (key) => {
    if (!isEditing) {
      const headCell = dialogueHeadCells.find((cell) => cell.id === key)
      if (headCell && headCell.type === "datetime") {
        return formatDateTime(row[key]) // Format datetime when not editing
      }
      return row[key] // Default rendering for other types
    }

    const headCell = dialogueHeadCells.find((cell) => cell.id === key)
    if (!headCell) return null

    switch (headCell.type) {
      case "autocomplete":
        return (
          <AutocompleteField
            label={headCell.label}
            endpoint={headCell.endpoint}
            value={editedData[key]}
            onSelectionChange={(newValue) =>
              setEditedData({ ...editedData, [key]: newValue })
            }
            disabled={headCell.disabled}
          />
        )
      case "string":
        return (
          <TextField
            type="text"
            name={key}
            disabled={headCell.disabled}
            value={editedData[key]}
            onChange={handleChange}
            fullWidth
            variant="outlined"
            size="small"
            sx={{
              margin: 0,
              padding: 0,
              height: "30px",
              lineHeight: "1.5",
              ".MuiInputBase-root": {
                height: "30px",
                fontSize: "14px",
              },
              ".MuiOutlinedInput-notchedOutline": {
                borderWidth: "1px",
              },
            }}
          />
        )
      case "datetime":
        return (
          <TextField
            type="datetime-local"
            name={key}
            disabled={headCell.disabled}
            value={formatDateTimeLocal(editedData[key])}
            onChange={handleChange}
            fullWidth
            variant="outlined"
            size="small"
            sx={{
              margin: 0,
              padding: 0,
              height: "30px",
              lineHeight: "1.5",
              ".MuiInputBase-root": {
                height: "30px",
                fontSize: "14px",
              },
              ".MuiOutlinedInput-notchedOutline": {
                borderWidth: "1px",
              },
            }}
          />
        )
      case "enum":
        return (
          <TextField
            select
            name={key}
            disabled={headCell.disabled}
            value={editedData[key]}
            onChange={handleChange}
            fullWidth
            variant="outlined"
            size="small"
            sx={{
              margin: 0,
              padding: 0,
              height: "30px",
              lineHeight: "1.5",
              ".MuiInputBase-root": {
                height: "30px",
                fontSize: "14px",
              },
              ".MuiOutlinedInput-notchedOutline": {
                borderWidth: "1px",
              },
            }}
          >
            {headCell.enum.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
        )
      default:
        return (
          <TextField
            type="text"
            name={key}
            value={editedData[key]}
            disabled={headCell.disabled}
            onChange={handleChange}
            fullWidth
            variant="outlined"
            size="small"
            sx={{
              margin: 0,
              padding: 0,
              height: "30px",
              lineHeight: "1.5",
              ".MuiInputBase-root": {
                height: "30px",
                fontSize: "14px",
              },
              ".MuiOutlinedInput-notchedOutline": {
                borderWidth: "1px",
              },
            }}
          />
        )
    }
  }

  // Conditional rendering
  if (!rowData || !dialogueHeadCells || !fieldLabels) {
    return <div>Loading or No data available</div>
  }

  return (
    <div>
      <Toolbar>
        <Button color="inherit" onClick={() => navigate(`/wp-admin/payment`)}>
          <BackIcon />
        </Button>
        <Typography variant="h6" sx={{ flexGrow: 1 }}>
          Payment
        </Typography>
        {isEditing ? (
          <>
            <Button
              sx={{ marginRight: 2 }}
              variant="contained"
              color="primary"
              onClick={handleSaveClick}
            >
              Save
            </Button>
            <Button
              sx={{ marginRight: 2 }}
              variant="outlined"
              color="secondary"
              onClick={() => {
                setEditedData(row)
                setIsEditing(false)
              }}
            >
              Cancel
            </Button>
          </>
        ) : (
          <Button variant="contained" color="primary" onClick={handleEditClick}>
            Edit
          </Button>
        )}
      </Toolbar>
      <Table>
        <TableBody>
          {Object.keys(row).map((key) => (
            <TableRow key={key}>
              <TableCell component="th" scope="row">
                {fieldLabels[key] || key}
              </TableCell>
              <TableCell>{renderInputField(key)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Box mt={2}></Box>
    </div>
  )
}
