import * as React from "react"
import { useContext } from "react"
import { useParams, useLocation, useNavigate } from "react-router-dom"
import Button from "@mui/material/Button"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableRow from "@mui/material/TableRow"
import TextField from "@mui/material/TextField"
import MenuItem from "@mui/material/MenuItem"
import Box from "@mui/material/Box"
import Toolbar from "@mui/material/Toolbar"
import Typography from "@mui/material/Typography"
import BackIcon from "@mui/icons-material/ArrowBack"
import { NavigationContext } from "../../../context/NavigationContext"
import AutocompleteField from "./AutoCompleteField"
import api from "../../../apiConfig"
import { TripContext } from "../../../context/TripContext"

const formatDateTime = (dateString) => {
  if (!dateString) return "" // Return empty string if no date
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  }
  const date = new Date(dateString)
  return date.toLocaleString("en-US", options) // Adjust the locale if needed
}
const formatDateTimeLocal = (dateString) => {
  if (!dateString) return "" // Return empty string if no date is provided

  const date = new Date(dateString)

  // Format to YYYY-MM-DDTHH:MM in Asia/Colombo timezone
  const options = {
    timeZone: "Asia/Colombo",
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    hourCycle: "h23", // 24-hour format
  }

  // Format the date using Intl.DateTimeFormat
  const formatter = new Intl.DateTimeFormat("en-CA", options) // en-CA gives ISO-like date format
  const parts = formatter.formatToParts(date)

  // Extract date and time parts
  const year = parts.find((part) => part.type === "year")?.value
  const month = parts.find((part) => part.type === "month")?.value
  const day = parts.find((part) => part.type === "day")?.value
  const hour = parts.find((part) => part.type === "hour")?.value
  const minute = parts.find((part) => part.type === "minute")?.value

  return `${year}-${month}-${day}T${hour}:${minute}`
}


// const formatDateTimeLocal = (dateString) => {
//   if (!dateString) return "" // Return empty string if no date is provided
//   const date = new Date(dateString)
//   // Format to YYYY-MM-DDTHH:MM
//   return date.toISOString().slice(0, 16)
// }

export default function DialoguePage() {
  const location = useLocation()
  // Extract base route from the current location (either wp-admin or wp-manager)
  const baseRoute = location.pathname.includes("wp-admin")
    ? "wp-admin"
    : "wp-manager"

  const dialogueHeadCells = [
    {
      id: "id",
      type: "string",
      numeric: false,
      disabled: true,
      label: "Id",
    },
    {
      id: "published",
      type: "datetime",
      numeric: true,
      disabled: true,
      label: "Published",
    },
    {
      id: "pickup_location",
      type: "string",
      numeric: true,
      disabled: false,
      label: "Pickup Location",
    },
    {
      id: "driver",
      type: "autocomplete",
      numeric: true,
      disabled: false,
      label: "Driver",
      endpoint: "/api/v1/driver/list", // API endpoint for fetching driver options
    },
    {
      id: "pickup_time",
      type: "datetime",
      numeric: true,
      disabled: false,
      label: "Pickup Time",
    },
    {
      id: "destination",
      type: "string",
      numeric: true,
      disabled: false,
      label: "Destination",
    },
    {
      id: "trip_start_time",
      type: "datetime",
      numeric: true,
      disabled: true,
      label: "Trip Start Time",
    },
    {
      id: "trip_end_time",
      type: "datetime",
      numeric: true,
      disabled: true,
      label: "Trip End Time",
    },
    {
      id: "status",
      type: "enum",
      disabled: false,
      label: "Status",
      enum: [
        "CONFIRMED",
        "DRAFT",
        "CANCELLED",
        "STARTED",
        "WAITING_BEFORE",
        "WAITING_AFTER",
        "TRIPEND",
      ],
    },
    {
      id: "client_name",
      type: "string",
      numeric: true,
      disabled: true,
      label: "Client Name",
    },
    {
      id: "client_phone",
      type: "string",
      numeric: true,
      disabled: true,
      label: "Client Phone",
    },
    {
      id: "client_email",
      type: "string",
      numeric: true,
      disabled: true,
      label: "Client Email",
    },
    {
      id: "source",
      type: "enum",
      numeric: true,
      disabled: true,
      enum: ["ANDROID", "IOS", "WEB", "ADMIN", "UNKOWN"],
      label: "Source",
    },
    {
      id: "start_meter_reading",
      type: "number",
      numeric: true,
      disabled: false,
      label: "Start Meter Reading",
    },
    {
      id: "end_meter_reading",
      type: "number",
      numeric: true,
      disabled: false,
      label: "End Meter Reading",
    },
    {
      id: "waiting_start_time",
      type: "datetime",
      numeric: true,
      disabled: true,
      label: "Waiting Start Time",
    },
    {
      id: "waiting_time_sum",
      type: "number",
      numeric: true,
      disabled: true,
      label: "Total Waiting Time",
    },
    {
      id: "company_charge",
      type: "number",
      numeric: true,
      disabled: true,
      label: "Company Charge",
    },
    {
      id: "estimate_cost",
      type: "number",
      numeric: true,
      disabled: true,
      label: "Estimate Cost",
    },
    {
      id: "final_cost",
      type: "number",
      numeric: true,
      disabled: true,
      label: "Final Cost",
    },
  ]

  const navigate = useNavigate()
  const { id } = useParams()
  const [isEditing, setIsEditing] = React.useState(false)
  const [editedData, setEditedData] = React.useState({})
  const { rowData } = useContext(TripContext)
  const row = rowData.find((row) => row.id === id)
  console.log("dialogueHead", dialogueHeadCells) // Debugging line
  console.log("rowData", rowData)
  console.log("row", row)

  const fieldLabels = dialogueHeadCells.reduce((acc, cell) => {
    acc[cell.id] = cell.label
    return acc
  })

  const handleEditClick = () => {
    setIsEditing(true)
  }

  const handleSaveClick = () => {
    console.log("Edited Data", editedData)
    console.log("Row Data", row)
    const sendData = {
      propertyId: id,
      pickupLocation: editedData.pickup_location || row.pickup_location,
      destination: editedData.destination || row.destination,
      pickupTime: editedData.pickup_time || row.pickup_time,
      tripStatus: editedData.status || row.status,
      driverId: editedData.driver ? editedData.driver.driverId : row.driver_id,
      meterReading: {
        startMeterReading:
          editedData.startMeterReading || row.start_meter_reading,
        endMeterReading: editedData.endMeterReading || row.end_meter_reading,
      },
    }
    console.log("Send Data", sendData)
    setIsEditing(false)
    api.put(`/api/v1/trip/update/${id}`, sendData).then((response) => {
      if (response.data.status === 201) {
        console.log("Data updated!", response.data)
      } else {
        console.log("something went wrong!", response.data)
      }
    })
  }
  dialogueHeadCells.map((cell) => {
    console.log(cell.id)
  })
  const handleChange = (event) => {
    const { name, value } = event.target
    setEditedData({ ...editedData, [name]: value })
  }

  const renderInputField = (key) => {
    console.log("Key", key)
    console.log("Row", row)
    console.log("Edited Data", editedData)
    const headCell = dialogueHeadCells.find((cell) => cell.id === key)
    if (!headCell) return null
    if (!isEditing) {
      if (key === "driver")
        if (editedData["driver"])
          return `${editedData["driver"].name} (${editedData["driver"].phone})`
        else return row["driver"]
      else if (headCell.type === "datetime") return formatDateTime(row[key])
      else return editedData[key] || row[key]
    }

    switch (headCell.type) {
      case "autocomplete":
        return (
          <AutocompleteField
            label={headCell.label}
            endpoint={headCell.endpoint}
            value={
              editedData[key]
                ? `${editedData[key].name} (${editedData[key].phone})`
                : row[key] || ""
            }
            onSelectionChange={(newValue) => {
              setEditedData({ ...editedData, [key]: newValue })
            }}
            disabled={headCell.disabled}
          />
        )
      case "string":
        return (
          <TextField
            type="text"
            name={key}
            disabled={headCell.disabled}
            value={editedData[key] || row[key] || ""}
            onChange={handleChange}
            fullWidth
            variant="outlined"
            size="small"
            sx={{
              margin: 0,
              padding: 0,
              height: "30px",
              lineHeight: "1.5",
              ".MuiInputBase-root": {
                height: "30px",
                fontSize: "14px",
              },
              ".MuiOutlinedInput-notchedOutline": {
                borderWidth: "1px",
              },
            }}
          />
        )
      case "datetime":
        return (
          <TextField
            type="datetime-local"
            name={key}
            disabled={headCell.disabled}
            value={
              formatDateTimeLocal(editedData[key]) ||
              formatDateTimeLocal(row[key]) ||
              ""
            }
            onChange={handleChange}
            fullWidth
            variant="outlined"
            size="small"
            sx={{
              margin: 0,
              padding: 0,
              height: "30px",
              lineHeight: "1.5",
              ".MuiInputBase-root": {
                height: "30px",
                fontSize: "14px",
              },
              ".MuiOutlinedInput-notchedOutline": {
                borderWidth: "1px",
              },
            }}
          />
        )
      case "enum":
        return (
          <TextField
            select
            name={key}
            disabled={headCell.disabled}
            value={editedData[key] || row[key] || ""}
            onChange={handleChange}
            fullWidth
            variant="outlined"
            size="small"
            sx={{
              margin: 0,
              padding: 0,
              height: "30px",
              lineHeight: "1.5",
              ".MuiInputBase-root": {
                height: "30px",
                fontSize: "14px",
              },
              ".MuiOutlinedInput-notchedOutline": {
                borderWidth: "1px",
              },
            }}
          >
            {headCell.enum.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
        )
      default:
        return (
          <TextField
            type="text"
            name={key}
            value={editedData[key] || row[key] || ""}
            disabled={headCell.disabled}
            onChange={handleChange}
            fullWidth
            variant="outlined"
            size="small"
            sx={{
              margin: 0,
              padding: 0,
              height: "30px",
              lineHeight: "1.5",
              ".MuiInputBase-root": {
                height: "30px",
                fontSize: "14px",
              },
              ".MuiOutlinedInput-notchedOutline": {
                borderWidth: "1px",
              },
            }}
          />
        )
    }
  }

  // Conditional rendering
  if (!rowData || !dialogueHeadCells || !fieldLabels) {
    return <div>Loading or No data available</div>
  }

  return (
    <div>
      <Toolbar>
        <Button color="inherit" onClick={() => navigate(`/${baseRoute}/trip`)}>
          <BackIcon />
        </Button>
        <Typography variant="h6" sx={{ flexGrow: 1 }}>
          Trips
        </Typography>

        {isEditing ? (
          <>
            <Button
              sx={{ marginRight: 2 }}
              variant="contained"
              color="primary"
              onClick={handleSaveClick}
            >
              Save
            </Button>

            <Button
              sx={{ marginRight: 2 }}
              variant="outlined"
              color="secondary"
              onClick={() => setIsEditing(false)}
            >
              Cancel
            </Button>
          </>
        ) : (
          <Button variant="contained" color="primary" onClick={handleEditClick}>
            Edit
          </Button>
        )}
      </Toolbar>

      <Table>
        <TableBody>
          {Object.keys(dialogueHeadCells).map((key, cell) => (
            <TableRow key={dialogueHeadCells[cell].id}>
              <TableCell component="th" scope="row">
                {fieldLabels[dialogueHeadCells[cell].id] ||
                  dialogueHeadCells[cell].id}
              </TableCell>
              <TableCell>
                {renderInputField(dialogueHeadCells[cell].id)}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Box mt={2}></Box>
    </div>
  )
}
