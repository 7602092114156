import * as React from "react"
import { useContext } from "react"
import { useParams, useLocation, useNavigate } from "react-router-dom"
import Button from "@mui/material/Button"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableRow from "@mui/material/TableRow"
import TextField from "@mui/material/TextField"
import MenuItem from "@mui/material/MenuItem"
import Box from "@mui/material/Box"
import Toolbar from "@mui/material/Toolbar"
import Typography from "@mui/material/Typography"
import BackIcon from "@mui/icons-material/ArrowBack"
import AutocompleteField from "./AutoCompleteField"
import api from "../../../apiConfig"
import { DriverContext } from "../../../context/DriverContext"

export default function DriverDialoguePage() {
  const dialogueHeadCells = [
    {
      id: "id",
      numeric: false,
      disablePadding: true,
      label: "Driver ID",
      disabled: true,
    },
    {
      id: "status",
      type: "enum",
      disablePadding: false,
      label: "Status",
      enum: ["ACTIVE", "INACTIVE", "DEMO"],
      disabled: false,
    },
    {
      id: "license",
      numeric: false,
      disablePadding: false,
      label: "License",
      disabled: false,
    },
    {
      id: "phone",
      numeric: false,
      disablePadding: false,
      label: "Phone Number",
      disabled: true,
    },
    {
      id: "phoneSecondary",
      numeric: false,
      disablePadding: false,
      label: "Secondary Phone Number*",
      disabled: true,
    },
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: "Name",
      disabled: false,
    },
    {
      id: "email",
      numeric: false,
      disablePadding: false,
      label: "Email",
      disabled: false,
    },
    {
      id: "address",
      numeric: false,
      disablePadding: false,
      label: "Address",
      disabled: false,
    },
    {
      id: "companyCharge",
      numeric: true,
      disablePadding: false,
      label: "Company Charge",
      disabled: true,
    },
  ]

  const navigate = useNavigate()
  const { id } = useParams()
  const [isEditing, setIsEditing] = React.useState(false)
  const { rowData } = useContext(DriverContext)
  const row = rowData.find((row) => row.id === id)
  const [savedData, setSavedData] = React.useState(row)
  const [editedData, setEditedData] = React.useState(row)
  console.log("dialogueHead", dialogueHeadCells) // Debugging line
  console.log("rowData", rowData)
  console.log("row", row)

  const fieldLabels = dialogueHeadCells.reduce((acc, cell) => {
    acc[cell.id] = cell.label
    return acc
  })

  const handleEditClick = () => {
    setIsEditing(true)
  }

  const handleResetPassword = () => {
    const send_data = {
      phone: editedData.phone || row.phone,
      email: editedData.email || row.email,
    }
    api
      .put(`/api/v1/users/resetpassword`, {
        ...send_data,
      })
      .then((response) => {
        if (response.data.status === 201) {
          console.log("Data updated!", response.data)
          alert("Password Resetted!")
          setIsEditing(false)
        } else {
          console.log("something went wrong!", response.data)
          alert("Something went wrong")
        }
      })
  }
  const handleSaveClick = () => {
    console.log(dialogueHeadCells)
    console.log(editedData)
    setIsEditing(false)
    console.log("Edited Data", editedData)
    const send_data = {
      driverId: id,
      status: editedData.status || savedData.status,
      license: editedData.license || savedData.license,
      phone: editedData.phone || savedData.phone,
      phoneSecondary: editedData.phoneSecondary || savedData.phoneSecondary,
      name: editedData.name || savedData.name,
      email: editedData.email || savedData.email,
      address: editedData.address || savedData.address,
    }
    api
      .put(`/api/v1/driver/${id}`, {
        ...send_data,
      })
      .then((response) => {
        if (response.data.status === 201) {
          const dtas = {
            id: id,
            status: editedData.status || savedData.status,
            license: editedData.license || savedData.license,
            phone: editedData.phone || savedData.phone,
            phoneSecondary:
              editedData.phoneSecondary || savedData.phoneSecondary,
            name: editedData.name || savedData.name,
            email: editedData.email || savedData.email,
            address: editedData.address || savedData.address,
            companyCharge: row.companyCharge
          }
          setSavedData(dtas)
          alert("Data updated")
          console.log("Data updated!", response.data)
          setIsEditing(false)
        } else {
          console.log("something went wrong!", response.data)
        }
      })
  }

  const handleChange = (event) => {
    const { name, value } = event.target
    setEditedData({ ...editedData, [name]: value })
  }

  const formatDateTime = (dateString) => {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    }
    const date = new Date(dateString)
    return date.toLocaleString("en-US", options) // Adjust the locale if needed
  }

  const formatDateTimeLocal = (dateString) => {
    if (!dateString) return "" // Return empty string if no date is provided
    const date = new Date(dateString)
    // Format to YYYY-MM-DDTHH:MM
    return date.toISOString().slice(0, 16)
  }

  const renderInputField = (key) => {
    console.log("saved Data",savedData)
    if (!isEditing) {
      const headCell = dialogueHeadCells.find((cell) => cell.id === key)
      if (headCell && headCell.type === "datetime") {
        return formatDateTime(savedData[key]) // Format datetime when not editing
      }
      return savedData[key] // Default rendering for other types
    }

    const headCell = dialogueHeadCells.find((cell) => cell.id === key)
    if (!headCell) return null

    switch (headCell.type) {
      case "autocomplete":
        return (
          <AutocompleteField
            label={headCell.label}
            endpoint={headCell.endpoint}
            value={editedData[key]}
            onSelectionChange={(newValue) =>
              setEditedData({ ...editedData, [key]: newValue })
            }
            disabled={headCell.disabled}
          />
        )
      case "string":
        return (
          <TextField
            type="text"
            name={key}
            disabled={headCell.disabled}
            value={editedData[key]}
            onChange={handleChange}
            fullWidth
            variant="outlined"
            size="small"
            sx={{
              margin: 0,
              padding: 0,
              height: "30px",
              lineHeight: "1.5",
              ".MuiInputBase-root": {
                height: "30px",
                fontSize: "14px",
              },
              ".MuiOutlinedInput-notchedOutline": {
                borderWidth: "1px",
              },
            }}
          />
        )
      case "datetime":
        return (
          <TextField
            type="datetime-local"
            name={key}
            disabled={headCell.disabled}
            value={formatDateTimeLocal(editedData[key])}
            onChange={handleChange}
            fullWidth
            variant="outlined"
            size="small"
            sx={{
              margin: 0,
              padding: 0,
              height: "30px",
              lineHeight: "1.5",
              ".MuiInputBase-root": {
                height: "30px",
                fontSize: "14px",
              },
              ".MuiOutlinedInput-notchedOutline": {
                borderWidth: "1px",
              },
            }}
          />
        )
      case "enum":
        return (
          <TextField
            select
            name={key}
            disabled={headCell.disabled}
            value={editedData[key]}
            onChange={handleChange}
            fullWidth
            variant="outlined"
            size="small"
            sx={{
              margin: 0,
              padding: 0,
              height: "30px",
              lineHeight: "1.5",
              ".MuiInputBase-root": {
                height: "30px",
                fontSize: "14px",
              },
              ".MuiOutlinedInput-notchedOutline": {
                borderWidth: "1px",
              },
            }}
          >
            {headCell.enum.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
        )
      default:
        return (
          <TextField
            type="text"
            name={key}
            value={editedData[key]}
            disabled={headCell.disabled}
            onChange={handleChange}
            fullWidth
            variant="outlined"
            size="small"
            sx={{
              margin: 0,
              padding: 0,
              height: "30px",
              lineHeight: "1.5",
              ".MuiInputBase-root": {
                height: "30px",
                fontSize: "14px",
              },
              ".MuiOutlinedInput-notchedOutline": {
                borderWidth: "1px",
              },
            }}
          />
        )
    }
  }

  // Conditional rendering
  if (!rowData || !dialogueHeadCells || !fieldLabels) {
    return <div>Loading or No data available</div>
  }

  return (
    <div>
      <Toolbar>
        <Button color="inherit" onClick={() => navigate(`/wp-admin/driver`)}>
          <BackIcon />
        </Button>
        <Typography variant="h6" sx={{ flexGrow: 1 }}>
          Edit Driver
        </Typography>
        {isEditing ? (
          <>
            <Button
              sx={{ marginRight: 2 }}
              variant="contained"
              color="primary"
              onClick={handleSaveClick}
            >
              Save
            </Button>

            <Button
              variant="outlined"
              color="secondary"
              onClick={() => setIsEditing(false)}
            >
              Cancel
            </Button>
          </>
        ) : (
          <>
            <Button
              variant="contained"
              color="primary"
              onClick={handleResetPassword}
              style={{ marginRight: "10px" }} // Add space to the right of this button
            >
              Reset Password
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleEditClick}
            >
              Edit
            </Button>
          </>
        )}
      </Toolbar>
      <Table>
        <TableBody>
          {Object.keys(row).map((key) => (
            <TableRow key={key}>
              <TableCell component="th" scope="row">
                {fieldLabels[key] || key}
              </TableCell>
              <TableCell>{renderInputField(key)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Box mt={2}></Box>
    </div>
  )
}
