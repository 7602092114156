import * as React from "react"
import { useContext } from "react"
import { useNavigate } from "react-router-dom"
import Box from "@mui/material/Box"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TablePagination from "@mui/material/TablePagination"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import Checkbox from "@mui/material/Checkbox"
import FormControlLabel from "@mui/material/FormControlLabel"
import Switch from "@mui/material/Switch"
import EnhancedTableToolbar from "./TableToolBar"
import EnhancedTableHead from "./TableHead"
import { DriverContext } from "../../../context/DriverContext"

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) {
      return order
    }
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}

export default function EnhancedTable({
  title = "Data Table",
  count,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
}) {
  const { rowData, setRowData } = useContext(DriverContext)

  const headCells = [
    { id: "id", numeric: false, disablePadding: true, label: "Driver ID" },
    {
      id: "status",
      type: "enum",
      disablePadding: false,
      label: "Status",
      enum: ["ACTIVE", "INACTIVE", "DEMO"],
    },
    {
      id: "license",
      numeric: false,
      disablePadding: false,
      label: "License",
    },
    { id: "phone", numeric: false, disablePadding: false, label: "Phone" },
    { id: "name", numeric: false, disablePadding: false, label: "Name" },
    { id: "email", numeric: false, disablePadding: false, label: "Email" },
    {
      id: "address",
      numeric: false,
      disablePadding: false,
      label: "Address",
    },
    {
      id: "companyCharge",
      numeric: false,
      disablePadding: false,
      label: "Company Charge",
    },
  ]

  console.log("headCells", headCells)
  const [order, setOrder] = React.useState("asc")
  const [orderBy, setOrderBy] = React.useState(headCells[0].id)
  const [selected, setSelected] = React.useState([])
  const [dense, setDense] = React.useState(false)
  const [dialogOpen, setDialogOpen] = React.useState(false)
  const [selectedRowData, setSelectedRowData] = React.useState(null)
  const navigate = useNavigate()

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc"
    setOrder(isAsc ? "desc" : "asc")
    setOrderBy(property)
  }

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rowData.map((n) => n.id)
      setSelected(newSelected)
      return
    }
    setSelected([])
  }

  const handleClose = () => {
    setDialogOpen(false)
    setSelectedRowData(null)
  }

  const handleClick = (event, id) => {
    event.stopPropagation()

    const selectedIndex = selected.indexOf(id)
    let newSelected = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      )
    }
    setSelected(newSelected)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleRowClick = (event, row) => {
    navigate(`/wp-admin/driver/post/${row.id}`)
  }

  const handleChangeDense = (event) => {
    setDense(event.target.checked)
  }

  const isSelected = (id) => selected.indexOf(id) !== -1

  const emptyRows = page > 0 ? Math.max(0, rowsPerPage - rowData.length) : 0

  const visibleRows = React.useMemo(
    () =>
      stableSort(rowData, getComparator(order, orderBy)).slice(0, rowsPerPage),
    [order, orderBy, rowsPerPage, rowData]
  )

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          title="Drivers"
          headCells={headCells}
        />
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rowData.length}
              headCells={headCells}
            />
            <TableBody>
              {visibleRows.map((row, index) => {
                const isItemSelected = isSelected(row.id)
                const labelId = `enhanced-table-checkbox-${index}`

                return (
                  <TableRow
                    hover
                    onClick={(event) => handleRowClick(event, row)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                    selected={isItemSelected}
                    sx={{ cursor: "pointer" }}
                  >
                    {/* <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        onClick={(event) => handleClick(event, row.id)}
                        inputProps={{
                          "aria-labelledby": labelId,
                        }}
                      />
                    </TableCell> */}
                    {headCells
                      .filter((headCell) => headCell.id !== "id")
                      .map((headCell) => (
                        <TableCell
                          key={headCell.id}
                          align={headCell.numeric ? "right" : "left"}
                          sx={{ fontSize: "0.55rem" }}
                        >
                          {headCell.type === "datetime"
                            ? row[headCell.id]
                              ? new Date(row[headCell.id]).toLocaleString()
                              : ""
                            : row[headCell.id]}
                        </TableCell>
                      ))}
                  </TableRow>
                )
              })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  )
}
