import React, { useState, useEffect } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  MenuItem,
  Box,
  Toolbar,
  Typography,
  TableHead,
} from "@mui/material"
import BackIcon from "@mui/icons-material/ArrowBack"
import api from "../../../apiConfig"
import Checkbox from "@mui/material/Checkbox"
// import { CheckBox } from "@mui/icons-material"

export default function DialoguePage() {
  const navigate = useNavigate()
  const location = useLocation()
  const [isEditing, setIsEditing] = useState(false)
  const [editedData, setEditedData] = useState({})
  const [rowData, setRowData] = useState(null)
  const [rates, setRates] = useState([])
  const [fieldLabels, setFieldLabels] = useState(null)
  const [cityCharge, setCityCharge] = useState(0)

  const headCells = [
    {
      id: "waitingTime",
      type: "number",
      numeric: true,
      disablePadding: false,
      label: "Waiting Time",
    },
    {
      id: "waitingCharge",
      type: "number",
      numeric: true,
      disablePadding: false,
      label: "Waiting Charge",
    },
    {
      id: "cityCharge",
      type: "number",
      numeric: true,
      disablePadding: false,
      label: "City Charge",
    },
    {
      id: "cityLimit",
      type: "number",
      numeric: true,
      disablePadding: false,
      label: "City Limit",
    },
    {
      id: "regularCharge",
      type: "number",
      numeric: true,
      disablePadding: false,
      label: "Regular Charge",
    },
    {
      id: "regularLimit",
      type: "number",
      numeric: true,
      disablePadding: false,
      label: "Regular Limit",
    },
    {
      id: "regularRatePerKim",
      type: "number",
      numeric: true,
      disablePadding: false,
      label: "Regular Rate Per Km",
    },
    {
      id: "webStatus",
      type: "checkbox",
      numeric: true,
      disablePadding: false,
      label: "Web Status",
    },
    {
      id: "appStatus",
      type: "checkbox",
      numeric: true,
      disablePadding: false,
      label: "App Status",
    },
  ]

  useEffect(() => {
    setFieldLabels(
      headCells.reduce((acc, cell) => {
        acc[cell.id] = cell.label
        return acc
      }, {})
    )

    // Fetch Master File data
    api.get(`/api/v1/master_file`).then((response) => {
      if (response.data.status === 201) {
        const row = {
          waitingTime: response.data.data.waitingTime,
          waitingCharge: response.data.data.waitingCharge,
          cityCharge: response.data.data.cityCharge,
          cityLimit: response.data.data.cityLimit,
          regularCharge: response.data.data.regularCharge,
          regularLimit: response.data.data.regularLimit,
          regularRatePerKim: response.data.data.regularRatePerKim,
          webStatus: response.data.data.webStatus,
          appStatus: response.data.data.appStatus,
        }
        setRowData(row)
        setCityCharge(response.data.data.cityCharge)
      } else {
        console.log("Something went wrong!", response.data)
      }
    })

    // Fetch Company Rates
    api.get("/api/v1/companyRate/list").then((response) => {
      console.log("Company Rates fetched!", response.data)
      if (response.data.status === 200) {
        const fetchedRates = response.data.data.map((item, index) => ({
          ...item,
          previousUpperLimit: response.data.data[index].upperLimit,
          lowerLimit:
            index === 0 ? 0 : response.data.data[index - 1].upperLimit,
        }))
        setRates(fetchedRates)
      } else {
        console.log("Something went wrong!", response.data.data)
      }
    })
  }, [])

  const handleEditClick = () => {
    setIsEditing(true)
  }

  const handleSaveClick = () => {
    console.log("Rates", rowData)
    const updates = rates.map((rate) => ({
      previousUpperLimit: parseFloat(rate.previousUpperLimit) || 0.0,
      upperLimit: parseFloat(rate.upperLimit) || 0.0,
      rate: parseFloat(rate.rate) || 0.0,
    }))
    console.log("Ratesss ", updates)
    if (updates.length > 0) {
      setIsEditing(false)
      api.put(`/api/v1/companyRate`, updates).then((response) => {
        if (response.data.status === 201) {
          console.log("Data updated!", response.data)
        } else {
          console.log("Something went wrong!", response.data)
        }
      })
    }
    // Perform save action here (e.g., API call)

    api.put(`/api/v1/master_file/update`, rowData).then((response) => {
      if (response.data.status === 201) {
        console.log("Master File updated!", response.data)
      } else {
        console.log("Something went wrong!", response.data)
      }
    })
  }

  const handleUpperLimitChange = (index, value) => {
    const newRates = [...rates]
    newRates[index].upperLimit = value

    // Update the lower limit of the next item
    if (index < newRates.length - 1) {
      newRates[index + 1].lowerLimit = value
    }

    setRates(newRates)
  }

  const handleChange = (event) => {
    const { name, value } = event.target
    // console.log(rowData)
    setRowData({ ...rowData, [name]: parseInt(value) })
  }

  const renderInputField = (key) => {
    const headCell = headCells.find((cell) => cell.id === key)
    if (!headCell) return null

    if (headCell.type === "checkbox") {
      return (
        <Checkbox
          name={key}
          checked={rowData[key] || false}
          onChange={(e) => setRowData({ ...rowData, [key]: e.target.checked })}
        />
      )
    } else {
      return (
        <TextField
          type={headCell.type || "text"}
          name={key}
          inputprops={{ inputMode: "numeric" }}
          value={parseInt(rowData[key])}
          onChange={handleChange}
          fullWidth
          variant="outlined"
          size="small"
          sx={{
            margin: 0,
            padding: 0,
            height: "30px",
            lineHeight: "1.5",
            ".MuiInputBase-root": {
              height: "30px",
              fontSize: "14px",
            },
            ".MuiOutlinedInput-notchedOutline": {
              borderWidth: "1px",
            },
          }}
        />
      )
    }
  }

  // Conditional rendering
  if (!rowData || !fieldLabels) {
    return <div>Loading or No data available</div>
  }

  const handleRateChange = (index, value) => {
    const newRates = [...rates]
    newRates[index].rate = value
    setRates(newRates)
  }

  const renderValue = (key) => {
    const headCell = headCells.find((cell) => cell.id === key)
    if (headCell.type === "checkbox") {
      return (
        <Checkbox
          disabled
          name={key}
          checked={rowData[key] || false}
          // onChange={(value) =>
          //   setRowData({ ...rowData, [key]: !value })
          // }
        />
      )
    }
    return rowData[key]
  }

  return (
    <div>
      <Typography variant="h6">Master Files</Typography>
      <Table>
        <TableBody>
          {Object.keys(rowData).map((key) => (
            <TableRow key={key}>
              <TableCell component="th" scope="row">
                {fieldLabels[key] || key}
              </TableCell>
              <TableCell>
                {isEditing ? renderInputField(key) : renderValue(key)}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <Box height={100} />

      <Typography variant="h6">Rates</Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{"Lower Limit"}</TableCell>
            <TableCell>{"Upper Limit"}</TableCell>
            <TableCell>{"Rate"}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rates.map((rate, index) => (
            <TableRow key={rate.propertyId}>
              <TableCell>
                {index === 0 ? rowData.cityCharge : rate.lowerLimit}
              </TableCell>
              <TableCell>
                {isEditing ? (
                  <TextField
                    type="number"
                    inputProps={{ inputMode: "numeric" }}
                    name={`upperLimit-${index}`}
                    value={rate.upperLimit}
                    onChange={(e) =>
                      handleUpperLimitChange(index, e.target.value)
                    }
                    fullWidth
                    variant="outlined"
                    size="small"
                  />
                ) : (
                  rate.upperLimit
                )}
              </TableCell>
              <TableCell>
                {isEditing ? (
                  <TextField
                    type="number"
                    name={`rate-${index}`}
                    value={rate.rate}
                    onChange={(e) => handleRateChange(index, e.target.value)}
                    fullWidth
                    variant="outlined"
                    size="small"
                  />
                ) : (
                  rate.rate
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Box mt={2}>
        {isEditing ? (
          <>
            <Button
              sx={{ marginRight: 2 }}
              variant="contained"
              color="primary"
              onClick={handleSaveClick}
            >
              Save
            </Button>
            <Button
              sx={{ marginRight: 2 }}
              variant="outlined"
              color="secondary"
              onClick={() => setIsEditing(false)}
            >
              Cancel
            </Button>
          </>
        ) : (
          <Button variant="contained" color="primary" onClick={handleEditClick}>
            Edit
          </Button>
        )}
      </Box>
    </div>
  )
}
