import React, { useState, useEffect } from "react"
import { Box, Typography, CardMedia } from "@mui/material"

const testimonials = [
  {
    imgSrc: "assets/cards/image1.png",
    name: "1",
  },
  {
    imgSrc: "assets/cards/image2.png",
    name: "2",
  },
  {
    imgSrc: "assets/cards/image3.png",
    name: "3",
  },
  {
    imgSrc: "assets/cards/image4.png",
    name: "4",
  },
  {
    imgSrc: "assets/cards/image5.png",
    name: "5",
  },
  {
    imgSrc: "assets/cards/image6.png",
    name: "6",
  },
]

const MotivateCards = () => {
  const [currentIndex, setCurrentIndex] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length)
    }, 8000)

    return () => clearInterval(interval)
  }, [])

  return (
    <Box
      sx={{
        // padding: { xs: "10px", sm: "20px" },
        position: "fixed",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        position: "relative",
        overflow: "hidden",
        mb: 0,
        height: "287px",
        backgroundColor: "#fff",
        width: "400px",
      }}
    >
      <Box
        sx={{
          width: "100%",
          //   maxWidth: "600px",
          height: "400px",
          position: "relative",
        }}
      >
        {testimonials.map((testimonial, index) => (
          <Box
            key={index}
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              opacity: currentIndex === index ? 1 : 0,
              transition: "opacity 2s ease-in-out",
              display: "flex",
              flexDirection: "column",
              justifyContent: testimonial.content ? "center" : "flex-start",
              alignItems: "center",
              textAlign: "center",
              //   padding: "20px",
              boxSizing: "border-box",
              zIndex: currentIndex === index ? 1 : 0,
              visibility: currentIndex === index ? "visible" : "hidden",
            }}
          >
            {/* <Typography
              variant={testimonial.content ? "h4" : "h4"}
              sx={{
                fontWeight: "bold",
                color: "#1e5675",
                flexGrow: 1,
                paddingBottom: "30px", // Adjust padding to avoid overlap
              }}
            >
              {testimonial.title}
            </Typography>

            {testimonial.content && (
              <Typography
                variant="p"
                fontSize={22}
                color="#1a445a"
                sx={{ marginTop: 1, paddingBottom: "40px" }} // Adjust padding to avoid overlap
              >
                {testimonial.content}
              </Typography>
            )} */}

            {/* Image at the bottom-right corner */}
            <CardMedia
              component="img"
              alt={testimonial.name}
              image={testimonial.imgSrc}
              sx={{
                position: "absolute",
                bottom: 0,
                right: 0,
                width: "auto", // default image width
                height: "auto", // default image height
                maxWidth: "400px", // limit max size
                maxHeight: "300px", // limit max size
                margin: 0,
                marginBottom: 0,
                marginRight: 0,
                padding: 0,
              }}
            />
          </Box>
        ))}
      </Box>
    </Box>
  )
}

export default MotivateCards
