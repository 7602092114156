// src/AboutUs.js
import React from "react"
import { Typography, Container, Box } from "@mui/material"
import { Helmet } from "react-helmet-async"

function AboutUs() {
  return (
    <>
      <Helmet>
        <title>About Us</title>
        <meta name="description" content="This is the about us page" />
        <link rel="canonical" href="/aboutus" />
      </Helmet>
      <Container maxWidth="md" sx={{ padding: 4 }}>
        <Box sx={{ marginBottom: 4 }}>
          <Typography variant="h1" gutterBottom sx={{ fontSize: "2rem" }}>
            About Us
          </Typography>
          <Typography paragraph>
            We are a Sri Lankan service company in the drink drive category
            operating from Colombo and providing services throughout the western
            province in Sri Lanka.
          </Typography>
          <Typography paragraph>
            We are a designated driver service. This means ‘we drive’ our
            customers home safely in the comfort of their own cars when they are
            under the influence.
          </Typography>
          <Typography paragraph>
            Our business makes our customers happy by letting them enjoy
            evening/night parties with drinks as they wish and facilitating the
            creation of an intelligent community who always obey the country's
            law when they are under the influence with the use of alcoholic
            beverages.
          </Typography>
          <Typography paragraph>
            Our certified chauffeurs are dedicated to offering professional
            service with utmost care. We connect with chauffeurs online
            throughout the service and ensure best-in-class service to our
            clients.
          </Typography>
        </Box>
      </Container>
    </>
  )
}

export default AboutUs
