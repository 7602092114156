import React from "react"
import { Stack, Typography, Box, Link } from "@mui/material"
import FacebookIcon from "@mui/icons-material/Facebook"
import TwitterIcon from "@mui/icons-material/Twitter"
import InstagramIcon from "@mui/icons-material/Instagram"
import LinkedInIcon from "@mui/icons-material/LinkedIn"
import YouTubeIcon from "@mui/icons-material/YouTube"

const Footer = () => {
  const scrollToAboutUs = () => {
    const aboutUsSection = document.getElementById("aboutus")
    if (aboutUsSection) {
      aboutUsSection.scrollIntoView({ behavior: "smooth", block: "start" })
    }
  }

  return (
    <Box
      sx={{
        backgroundColor: "#000000", // Set the background color
        padding: { xs: "20px", md: "40px" }, // Add padding for mobile and larger screens
        color: "white",
      }}
    >
      <Stack
        direction={{ xs: "column", md: "row" }}
        spacing={4}
        justifyContent="center"
        alignItems="flex-start"
      >
        {/* Quick Links */}
        <Stack
          textAlign="left"
          spacing={2}
          sx={{ width: { xs: "100%", md: "50%" } }}
        >
          <Typography color="#FFCF43" fontSize={{ xs: "18px", md: "20px" }}>
            Quick Links
          </Typography>
          <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
            <Stack spacing={2}>
              <Link
                href="#"
                color="inherit"
                underline="none"
                onClick={scrollToAboutUs}
              >
                About Us
              </Link>
              <Link href="#" color="inherit" underline="none">
                Rates
              </Link>
              <Link href="#" color="inherit" underline="none">
                T&C
              </Link>
              <Link href="#" color="inherit" underline="none">
                Careers
              </Link>
            </Stack>
            <Stack spacing={2}>
              <Link href="#" color="inherit" underline="none">
                Testimonials
              </Link>
              <Link href="#" color="inherit" underline="none">
                FAQ
              </Link>
            </Stack>
          </Stack>
        </Stack>

        {/* Reach Us */}
        <Stack
          spacing={2}
          textAlign="left"
          sx={{ width: { xs: "100%", md: "50%" } }}
        >
          <Typography color="#FFCF43" fontSize={{ xs: "18px", md: "20px" }}>
            Reach Us
          </Typography>
          <Stack direction="column" spacing={1}>
            <Typography color="white">You Drink - We Drive,</Typography>
            <Typography color="white">Shop No 157, 2nd Floor,</Typography>
            <Typography color="white">Metro Shopping Complex,</Typography>
            <Typography color="white">Kandy Road, Kirillawla,</Typography>
            <Typography color="white">Kadawatha 11850</Typography>
            <Stack direction="row" spacing={1} alignItems="center">
              <img
                src="assets/images/navbar_call.png"
                alt="phone"
                style={{ height: "20px", width: "20px" }}
              />
              <Typography color="white">0777 40 00 40</Typography>
            </Stack>
            <Stack direction="row" spacing={1} alignItems="center">
              <img
                src="assets/images/navbar_mail.png"
                alt="email"
                style={{ height: "20px", width: "20px" }}
              />
              <Typography color="white">hello@youdrinkwedrive.lk</Typography>
            </Stack>
          </Stack>
        </Stack>
      </Stack>

      {/* Social Media Icons */}
      <Stack
        direction="row"
        spacing={2}
        paddingTop={5}
        justifyContent="center"
        alignItems="center"
      >
        <Typography
          color="white"
          fontFamily="Inter"
          fontSize={{ xs: "14px", md: "16px" }}
        >
          Connect With Us
        </Typography>
        <Link
          href="https://web.facebook.com/youdrinkwedrive.lk"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FacebookIcon style={{ color: "white" }} />
        </Link>
        {/* <TwitterIcon style={{ color: "white" }} />
        <InstagramIcon style={{ color: "white" }} />
        <LinkedInIcon style={{ color: "white" }} />
        <YouTubeIcon style={{ color: "white" }} /> */}
      </Stack>
    </Box>
  )
}

export default Footer
