import * as React from "react"
import { useContext } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  MenuItem,
  Box,
  Typography,
  Button,
  Toolbar,
} from "@mui/material"
import BackIcon from "@mui/icons-material/ArrowBack"
import api from "../../../apiConfig"

export default function DriverAddNew() {
  const navigate = useNavigate()
  const [editedData, setEditedData] = React.useState({})

  const createData = [
    {
      id: "name",
      type: "string",
      numeric: false,
      disabled: false,
      label: "Name",
    },
    {
      id: "email",
      type: "string",
      numeric: false,
      disabled: false,
      label: "Email",
    },
    {
      id: "phone",
      type: "string",
      numeric: false,
      disabled: false,
      label: "Phone",
    },
    {
      id: "phoneSecondary",
      type: "string",
      numeric: false,
      disabled: false,
      label: "Secondary Phone",
    },
    {
      id: "address",
      type: "string",
      numeric: false,
      disabled: false,
      label: "Address",
    },
    {
      id: "license",
      type: "string",
      numeric: false,
      disabled: false,
      label: "License",
    },
    {
      id: "status",
      type: "enum",
      numeric: false,
      disabled: false,
      label: "Status",
      enum: ["ACTIVE", "INACTIVE", "DEMO"],
    },
  ]

  const handleSaveClick = () => {
    if (editedData.name && editedData.email && editedData.phone) {
      const data = {
        name: editedData.name,
        email: editedData.email,
        phone: editedData.phone,
        phoneSecondary: editedData.phoneSecondary,
        address: editedData.address,
        license: editedData.license,
        status: editedData.status,
      }
      console.log("Save clicked", editedData)
      api
        .post("api/v1/driver", data)
        .then((response) => {
          alert("Driver added successfully")
          console.log("Response:", response)
          navigate("/wp-admin/driver")
        })
        .catch((error) => {
          alert("Error saving data : duplicate entry")
          console.error("Error saving data", error)
        })
    }
  }

  const handleChange = (event) => {
    const { name, value } = event.target
    setEditedData({ ...editedData, [name]: value })
  }

  const renderInputField = (key) => {
    const headCell = createData.find((cell) => cell.id === key)
    if (!headCell) return null

    switch (headCell.type) {
      case "string":
        return (
          <TextField
            type="text"
            name={key}
            value={editedData[key] || ""}
            onChange={handleChange}
            fullWidth
            variant="outlined"
            size="small"
          />
        )
      case "datetime":
        return (
          <TextField
            type="datetime-local"
            name={key}
            value={editedData[key] || ""}
            onChange={handleChange}
            fullWidth
            variant="outlined"
            size="small"
          />
        )
      case "enum":
        return (
          <TextField
            select
            name={key}
            value={editedData[key] || ""}
            onChange={handleChange}
            fullWidth
            variant="outlined"
            size="small"
          >
            {headCell.enum.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
        )
      default:
        return (
          <TextField
            type="text"
            name={key}
            disabled={headCell.disabled}
            value={editedData[key] || ""}
            onChange={handleChange}
            fullWidth
            variant="outlined"
            size="small"
          />
        )
    }
  }

  if (!createData) {
    return <Typography>No data available to add a new trip.</Typography>
  }

  return (
    <Box>
      <Toolbar>
        <Button color="inherit" onClick={() => navigate(`/wp-admin/driver`)}>
          <BackIcon />
        </Button>

        <Typography variant="h6" gutterBottom>
          Add New Driver
        </Typography>
      </Toolbar>
      {/* <Typography variant="h6" gutterBottom>
        Add New Trip
      </Typography> */}
      <Table>
        <TableBody>
          {createData.map((cell) => (
            <TableRow key={cell.id}>
              <TableCell component="th" scope="row">
                {cell.label}
              </TableCell>
              <TableCell>{renderInputField(cell.id)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Box mt={2}>
        <Button variant="contained" color="primary" onClick={handleSaveClick}>
          Save
        </Button>
      </Box>
    </Box>
  )
}
