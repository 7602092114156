import React, { useState } from "react"
import { useNavigate, Outlet } from "react-router-dom"
import Box from "@mui/material/Box"
import Drawer from "@mui/material/Drawer"
import CssBaseline from "@mui/material/CssBaseline"
import Typography from "@mui/material/Typography"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemText from "@mui/material/ListItemText"
import Button from "@mui/material/Button"
import { LogoutRounded } from "@mui/icons-material"
import Cookies from "js-cookie" // Import js-cookie
import { AuthContext } from "../../context/AuthContext"
import { Avatar } from "@mui/material"
import api from "../../apiConfig"

const drawerWidth = 130 // Reduced drawer width

function PermanentDrawerLeft() {
  const { user, username, userType, login, logout } =
    React.useContext(AuthContext)
  const navigate = useNavigate()
  const [selection, setSelection] = useState("trip")

  const listItems = [
    { text: "Trips", path: "/wp-admin/trip", tab: "trip" },
    { text: "Drivers", path: "/wp-admin/driver", tab: "driver" },
    { text: "Payments", path: "/wp-admin/payment", tab: "payment" },
    { text: "Master File", path: "/wp-admin/master", tab: "master" },
    { text: "Users", path: "/wp-admin/users", tab: "user" },
    { text: "Insights", path: "/wp-admin/insights", tab: "insights" },
    { text: "Options", path: "/wp-admin/options", tab: "options" },
  ]

  const handleLogout = () => {
    // Handle logout logic here, like clearing tokens, etc.
    console.log("Logging out...")
    Cookies.remove("Authorization") // Remove the authToken cookie
    logout() // Call the logout function from the context
    delete api.defaults.headers.common["Authorization"]
    navigate("/wp-login") // Redirect to login page or any desired route
  }

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Drawer
        sx={{
          backgroundColor: "grey",
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            backgroundColor: "#E5E5E5",
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        variant="permanent"
        anchor="left"
      >
        <img
          src={"youdrinkwedrivelogo.png"}
          alt=""
          // style={{
          //   width: "80%", // Adjust width as per your design
          //   height: "auto",
          // }}
        />
        <Typography variant="h8" noWrap component="div" sx={{ padding: 2 }}>
          Hi Admin
        </Typography>
        <Box>
          <List>
            {listItems.map(({ text, path, tab }) => (
              <ListItem key={text} disablePadding>
                <ListItemButton
                  onClick={() => {
                    navigate(path)
                    setSelection(tab)
                  }}
                  sx={{
                    height: 30,
                    paddingLeft: 2,
                    paddingRight: 2,
                    width: "100%",
                    backgroundColor: tab === selection ? "#C5C5C5" : "",
                  }}
                >
                  <ListItemText
                    primary={text}
                    primaryTypographyProps={{ fontSize: "0.8rem" }}
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
          {/* Logout Button as List Item */}
          <ListItem disablePadding sx={{ mt: 25, mb: 2 }}>
            <ListItemButton
              onClick={handleLogout}
              sx={{
                height: 30,
                paddingLeft: 2,
                paddingRight: 2,
                width: "100%",
                // backgroundColor: "#FFCCCC", // Example color for logout button
              }}
            >
              <LogoutRounded />
              <ListItemText
                primary="Logout"
                primaryTypographyProps={{ fontSize: "0.8rem" }}
              />
            </ListItemButton>
          </ListItem>
        </Box>
      </Drawer>
      <Box
        component="main"
        sx={{ flexGrow: 1, bgcolor: "background.default", p: 3 }}
      >
        <Outlet />
      </Box>
    </Box>
  )
}

export default PermanentDrawerLeft
