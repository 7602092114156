import React from "react"
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
} from "@mui/material"
import { Helmet } from "react-helmet-async"

const RatesTable = () => {
  return (
    <>
      <Helmet>
        <title>Rates</title>
        <meta name="description" content="You Drink We Drive Rates page" />
        <link rel="canonical" href="/rates" />
      </Helmet>
      <TableContainer component={Paper}>
        <Typography
          variant="h1"
          gutterBottom
          align="center"
          sx={{ fontSize: "2rem" }}
        >
          Rates
        </Typography>
        <Table sx={{ minWidth: 650 }} aria-label="Rates Table">
          <TableHead>
            <TableRow>
              <TableCell>
                <strong>Package Name</strong>
              </TableCell>
              <TableCell align="left">
                <strong>Call-Up Charge</strong>
              </TableCell>
              <TableCell align="left">
                <strong>Complimentary</strong>
              </TableCell>
              <TableCell align="left">
                <strong>Charge for Extra Waiting Time & Distance</strong>
              </TableCell>
              <TableCell align="left">
                <strong>Conditions</strong>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>City Package</TableCell>
              <TableCell align="left">Rs.1500/-</TableCell>
              <TableCell align="left">
                <ul>
                  <li>5 Km (trip start from Colombo 1 to Colombo 15)</li>
                  <li>15 minute Waiting Time</li>
                </ul>
              </TableCell>
              <TableCell align="left">
                <ul>
                  <li>Rs. 350/- per each 15 minute slots</li>
                </ul>
              </TableCell>
              <TableCell align="left">
                No Promo code apply, with exceed of 10Km, automatically transfer
                to regular package.
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Regular Package</TableCell>
              <TableCell align="left">Rs.2000/-</TableCell>
              <TableCell align="left">
                <ul>
                  <li>10 Km</li>
                  <li>15 minute Waiting Time</li>
                </ul>
              </TableCell>
              <TableCell align="left">
                <ul>
                  <li>Rs 100/- per Km</li>
                  <li>Rs 350/- per each 15 minute slots</li>
                </ul>
              </TableCell>
              <TableCell align="left">
                Discount offer with promo codes.
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>

        <Typography variant="body2" sx={{ mt: 2, mx: 2 }}>
          <strong>Definition of waiting time:</strong> Trip reserved time to
          Trip start time (e.g., booking time is 8 pm and driver arrives at 8
          pm, then till 8:15 pm driver will wait free of charge). No waiting
          charges for road traffic.
        </Typography>
        <Typography variant="body2" sx={{ mt: 2, mx: 2 }}>
          <strong>Note:</strong> All App Bookings are subjected to special
          offers up to 25%.
        </Typography>
        <Typography variant="body2" sx={{ mt: 2, mx: 2, fontStyle: "italic" }}>
          **Conditions Apply
        </Typography>
      </TableContainer>
    </>
  )
}

export default RatesTable
