import React, { useState, useEffect } from "react"
import { Typography } from "@mui/material"

const AnimatedCounter = ({ targetDistance, targetCost, duration = 500 }) => {
  const [currentDistance, setCurrentDistance] = useState(0)
  const [currentCost, setCurrentCost] = useState(0)

  useEffect(() => {
    // Ensure target values are valid numbers
    const parsedTargetDistance = parseFloat(targetDistance)
    const parsedTargetCost = parseFloat(targetCost)

    if (isNaN(parsedTargetDistance) || isNaN(parsedTargetCost)) {
      console.error("Invalid target values:", targetDistance, targetCost)
      return
    }

    let startDistance = currentDistance
    let startCost = currentCost
    const startTime = Date.now()

    const animate = () => {
      const elapsedTime = Date.now() - startTime
      const progress = Math.min(elapsedTime / duration, 1)

      const updatedDistance =
        startDistance + (parsedTargetDistance - startDistance) * progress
      const updatedCost = startCost + (parsedTargetCost - startCost) * progress

      setCurrentDistance(updatedDistance)
      setCurrentCost(updatedCost)

      if (progress < 1) {
        requestAnimationFrame(animate)
      }
    }

    // Start the animation
    animate()
  }, [targetDistance, targetCost, duration, currentDistance, currentCost])

  return (
    <Typography style={{ fontSize: "1 rem" }}>
      Estimated Distance : {currentDistance.toFixed(1)} km <br />
                Cost : Rs. {currentCost.toFixed(2)}
    </Typography>
  )
}

export default AnimatedCounter
