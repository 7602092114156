import React from "react"
import {
  Box,
  Button,
  ButtonGroup,
  Stack,
  Typography,
  TextField,
  Checkbox,
  Link,
  colors,
} from "@mui/material"
import LocationField from "../../../../components/LocationField"

import TermsPopup from "../../../../popups/Terms" // Adjust the import based on your file structure
import AnimatedCounter from "../../../../components/DistanceCounter"
import EstimateForm from "./EstimateForm"
import ReservationForm from "./ReservationForm"

const MobileForm = ({
  selectedOption,
  setSelectedOption,
  pickupValue,
  setPickupValue,
  dropValue,
  setDropValue,
  distance,
  setDistance,
  formValues,
  setFormValues,
  errors,
  checkboxChecked,
  setCheckboxChecked,
  handleButtonClick,
  calculateDistance,
  handleReservation,
  openPopups,
  handleOpenPopup,
  handleClosePopup,
  estimateCost,
  estimatedCostValue,
}) => {
  return (
    <div
      id="news-bar"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        // height: "120vh", // Full viewport height
        overflow: "hidden", // Prevent overflow of the parent container
        // backgroundImage: `url('/welcome.jpeg')`, // Update the path to your image
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundAttachment: "fixed",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          backgroundColor: colors.common.white,
          color: colors.common.black,
          width: { xs: "80%", sm: "400px" },
          maxHeight: "120vh", // Max height for the box
          overflowY: "auto", // Enable vertical scrolling if needed
          padding: "20px",
        }}
      >
        <ButtonGroup
          variant="contained"
          aria-label="Basic button group"
          color="grey"
          sx={{
            width: "100%",
            marginBottom: "20px",
            backgroundColor: "black",
          }}
        >
          <Button
            sx={{
              flex: 1,
              backgroundColor:
                selectedOption === "estimate" ? "#FFCF43" : "grey",
              color: selectedOption === "estimate" ? "black" : "white",
            }}
            onClick={() => handleButtonClick("estimate")}
          >
            Estimate Cost
          </Button>
          <Button
            sx={{
              flex: 1,
              backgroundColor:
                selectedOption === "estimate" ? "grey" : "#FFCF43",
              color: selectedOption === "estimate" ? "white" : "black",
            }}
            onClick={() => handleButtonClick("reserve")}
          >
            Make Your Reservation
          </Button>
        </ButtonGroup>

        {/* {selectedOption === "estimate" ? (
          <Stack direction="column" spacing={2} width="100%">
            <LocationButton
              value={pickupValue}
              setValue={setPickupValue}
              label="Pickup Location"
            />
            <LocationButton
              value={dropValue}
              setValue={setDropValue}
              label="Drop Location"
            />
            <TextField
              label="Promo Code (Optional)"
              sx={{ width: "100%" }}
              value={formValues.promoCode}
              onChange={(e) =>
                setFormValues({ ...formValues, promoCode: e.target.value })
              }
            />
            {distance && estimatedCostValue && (
              <AnimatedCounter
                targetDistance={distance}
                targetCost={estimatedCostValue}
              />
            )}
            <Button
              fullWidth
              variant="contained"
              sx={{
                marginTop: "20px",
                backgroundColor: "#FFCF43",
                "&:hover": {
                  backgroundColor: "#FFD8A3", // Light yellow color on hover
                },
              }}
              onClick={async () => {
                try {
                  await estimateCost({
                    origin: pickupValue,
                    destination: dropValue,
                  })
                } catch (error) {
                  console.error("Error calculating distance:", error)
                }
              }}
            >
              Estimate
            </Button>
          </Stack>
        ) : (
          <Stack direction="column" spacing={2} width="100%">
            <LocationButton
              value={pickupValue}
              setValue={setPickupValue}
              label="Pickup Location"
              error={errors.pickup}
              helperText={errors.pickup ? "Pickup Location is required" : ""}
            />
            <LocationButton
              value={dropValue}
              setValue={setDropValue}
              label="Drop Location"
              error={errors.drop}
              helperText={errors.drop ? "Drop Location is required" : ""}
            />
            <TextField
              fullWidth
              label="Pickup Time"
              variant="outlined"
              margin="normal"
              type="datetime-local"
              value={formValues.pickup_time}
              onChange={(e) =>
                setFormValues({ ...formValues, pickup_time: e.target.value })
              }
              error={errors.pickup_time}
              helperText={errors.pickup_time ? "Pickup time is required" : ""}
              InputLabelProps={{ shrink: true }}
              inputProps={{
                min: new Date().toISOString().slice(0, 16),
              }}
              sx={{ backgroundColor: "white" }}
            />
            <TextField
              fullWidth
              label="Name"
              variant="outlined"
              margin="normal"
              value={formValues.name}
              onChange={(e) =>
                setFormValues({ ...formValues, name: e.target.value })
              }
              error={errors.name}
              helperText={errors.name ? "Name is required" : ""}
              sx={{ backgroundColor: "white" }}
            />
            <TextField
              fullWidth
              label="Email Address"
              variant="outlined"
              margin="normal"
              value={formValues.email}
              onChange={(e) =>
                setFormValues({ ...formValues, email: e.target.value })
              }
              error={errors.email}
              helperText={errors.email ? "Invalid email address" : ""}
              sx={{ backgroundColor: "white" }}
            />
            <TextField
              fullWidth
              label="Contact Number"
              variant="outlined"
              margin="normal"
              value={formValues.phone}
              onChange={(e) =>
                setFormValues({ ...formValues, phone: e.target.value })
              }
              error={errors.phone}
              helperText={errors.phone ? "Invalid phone number" : ""}
            />
            <TextField
              fullWidth
              label="Promo Code (Optional)"
              variant="outlined"
              margin="normal"
              value={formValues.promoCode}
              onChange={(e) =>
                setFormValues({ ...formValues, promoCode: e.target.value })
              }
            />
            <Stack direction="row" alignItems={"start"}>
              <Checkbox
                checked={checkboxChecked}
                onChange={(e) => setCheckboxChecked(e.target.checked)}
              />
              <Typography color={"black"} align="left">
                I have read and understand the{" "}
                <Link onClick={() => handleOpenPopup("About Us")}>
                  terms and conditions
                </Link>
              </Typography>
            </Stack>
            {errors.checkbox && (
              <Typography color="red" variant="caption">
                You must agree to the terms and conditions
              </Typography>
            )}
            <Button
              fullWidth
              variant="contained"
              sx={{
                marginTop: "20px",
                backgroundColor: "#FFCF43",
                "&:hover": {
                  backgroundColor: "#FFD8A3", // Light yellow color on hover
                },
              }}
              onClick={handleReservation}
            >
              Make Reservation
            </Button>
            <TermsPopup
              open={openPopups["About Us"]}
              onClose={() => handleClosePopup("About Us")}
              name={"About Us"}
            />
          </Stack>
        )} */}
        {selectedOption === "estimate" ? (
          <EstimateForm
            estimateCost={estimateCost}
            estimatedCostValue={estimatedCostValue}
            pickupValue={pickupValue}
            setPickupValue={setPickupValue}
            dropValue={dropValue}
            setDropValue={setDropValue}
            distance={distance}
            setDistance={setDistance}
            calculateDistance={calculateDistance}
            formValues={formValues}
            setFormValues={setFormValues}
            width="300px"
          />
        ) : (
          <ReservationForm
            pickupValue={pickupValue}
            setPickupValue={setPickupValue}
            dropValue={dropValue}
            setDropValue={setDropValue}
            formValues={formValues}
            setFormValues={setFormValues}
            errors={errors}
            checkboxChecked={checkboxChecked}
            setCheckboxChecked={setCheckboxChecked}
            handleReservation={handleReservation}
            openPopups={openPopups}
            handleOpenPopup={handleOpenPopup}
            handleClosePopup={handleClosePopup}
            width="300px"
          />
        )}
      </Box>
    </div>
  )
}

export default MobileForm
