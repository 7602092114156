import * as React from "react"
import { useNavigate, useLocation } from "react-router-dom"
import PropTypes from "prop-types"
import { alpha } from "@mui/material/styles"
import Typography from "@mui/material/Typography"
import IconButton from "@mui/material/IconButton"
import Tooltip from "@mui/material/Tooltip"
import DeleteIcon from "@mui/icons-material/Delete"
import SearchIcon from "@mui/icons-material/Search"
import FilterListIcon from "@mui/icons-material/FilterList"
import { AddCircleRounded } from "@mui/icons-material"
import Toolbar from "@mui/material/Toolbar"
import { DatePicker } from "@mui/lab"
import {
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material"

import { styled } from "@mui/material/styles"

const StyledTextField = styled(TextField)({
  "& .MuiOutlinedInput-input": {
    padding: "4px 8px", // Custom padding
  },
})

const StyledSelect = styled(Select)({
  "& .MuiSelect-select": {
    padding: "4px 8px", // Custom padding
  },
})

const formatDate = (date) => {
  return date.toISOString().split("T")[0] // Converts to "YYYY-MM-DD"
}
export default function EnhancedTableToolbar(props) {
  const { numSelected, title, headCells, onFilterChange } = props
  const navigate = useNavigate()
  const location = useLocation()
  const { selectedItem } = location.state || {}

  // Extract base route from the current location (either wp-admin or wp-manager)
  const baseRoute = location.pathname.includes("wp-admin")
    ? "wp-admin"
    : "wp-manager"

  // Date range state

  const today = new Date()
  const tomorrow = new Date(today)
  tomorrow.setDate(today.getDate() + 1)
  const [startDate, setStartDate] = React.useState(
    // formatDate(new Date("2020-01-01T00:00:00"))
  formatDate(today)
  )
  const [endDate, setEndDate] = React.useState(formatDate(tomorrow))

  // Status dropdown state
  const [status, setStatus] = React.useState("ALL")

  const handleStatusChange = (event) => {
    setStatus(event.target.value)
    // onFilterChange({ status: event.target.value, startDate, endDate }) // Call parent filter function
  }

  const handleDateChange = (newValue, name) => {
    if (name === "startDate") {
      setStartDate(newValue)
    } else {
      setEndDate(newValue)
    }
  }

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          {title}
        </Typography>
      )}
      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <>
          {/* Date Pickers for Date Range */}
          <StyledTextField
            label="Start Date"
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            InputLabelProps={{ shrink: true, style: { fontSize: "0.8rem" } }}
            sx={{ marginRight: 2, width: 250 }}
          />
          <StyledTextField
            label="End Date"
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            InputLabelProps={{ shrink: true, style: { fontSize: "0.8rem" } }}
            sx={{ marginRight: 2, width: 250 }}
          />

          {/* Status Dropdown */}
          <FormControl sx={{ minWidth: 120, marginRight: 2 }}>
            <InputLabel sx={{ fontSize: "0.8rem" }}>Status</InputLabel>{" "}
            <StyledSelect
              value={status}
              onChange={handleStatusChange}
              label="Status"
            >
              <MenuItem
                value="ALL"
                sx={{ fontSize: "0.8rem", padding: "4px 8px" }}
              >
                ALL
              </MenuItem>
              <MenuItem
                value="DRAFT"
                sx={{ fontSize: "0.8rem", padding: "4px 8px" }}
              >
                DRAFT
              </MenuItem>
              <MenuItem
                value="CONFIRMED"
                sx={{ fontSize: "0.8rem", padding: "4px 8px" }}
              >
                CONFIRMED
              </MenuItem>
              <MenuItem
                value="CANCELLED"
                sx={{ fontSize: "0.8rem", padding: "4px 8px" }}
              >
                CANCELLED
              </MenuItem>
              <MenuItem
                value="ARRIVED"
                sx={{ fontSize: "0.8rem", padding: "4px 8px" }}
              >
                ARRIVED
              </MenuItem>
              <MenuItem
                value="STARTED"
                sx={{ fontSize: "0.8rem", padding: "4px 8px" }}
              >
                STARTED
              </MenuItem>
              <MenuItem
                value="WAITING_BEFORE"
                sx={{ fontSize: "0.8rem", padding: "4px 8px" }}
              >
                WAITING (Before)
              </MenuItem>
              <MenuItem
                value="WAITING_AFTER"
                sx={{ fontSize: "0.8rem", padding: "4px 8px" }}
              >
                WAITING (During)
              </MenuItem>
              <MenuItem
                value="TRIPEND"
                sx={{ fontSize: "0.8rem", padding: "4px 8px" }}
              >
                TRIP END
              </MenuItem>
            </StyledSelect>
          </FormControl>
          {/* Add a small Search button*/}
          <IconButton
            onClick={
              () =>
                startDate !== null && endDate !== null
                  ? onFilterChange({ status, startDate, endDate })
                  : alert("Must Fill Start Date and End Date") // Call parent filter function
            }
          >
            <SearchIcon
              sx={{
                color: "blue",
              }}
            />
          </IconButton>


        </>
      )}
    </Toolbar>
  )
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  onFilterChange: PropTypes.func.isRequired, // Parent filter function to handle date and status changes
}
