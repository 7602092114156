import { create } from "@mui/material/styles/createTransitions"
import { createContext, useState, useMemo } from "react"

export const NavigationContext = createContext(null)

function NavigationContextProvider(props) {
  const [rowData, setRowData] = useState([])

  const contextValue = useMemo(
    () => ({
      rowData,
      setRowData,
    }),
    [ rowData]
  )

  // const contextValue = {
  //   rowData,
  //   setRowData,
  // }
  return (
    <NavigationContext.Provider value={contextValue}>
      {props.children}
    </NavigationContext.Provider>
  )
}

export default NavigationContextProvider
