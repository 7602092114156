import React from "react"
import {
  Box,
  List,
  Stack,
  Typography,
  ListItemIcon,
  ListItem,
  ListItemText,
  Button,
} from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import CheckIcon from "@mui/icons-material/Check"

const Mission = () => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      sx={{
        padding: { xs: "20px", md: "40px" }, // Add padding for mobile and larger screens
        width: "100%",
        flexDirection: { xs: "column", md: "row" }, // Stack vertically on small screens and horizontally on larger screens
      }}
    >
      <Stack
        direction="column"
        spacing={2}
        sx={{
          width: { xs: "100%", sm: "80%", md: "50%" }, // Adjust width for different screen sizes
          padding: { xs: "10px", md: "20px" }, // Add padding inside the stack
        }}
      >
        <Typography variant="h6" sx={{ fontSize: { xs: "20px", md: "24px" } }}>
          We Drive Your Car for You
        </Typography>
        <Typography
          sx={{ fontSize: { xs: "14px", md: "16px" }, textAlign: "justify" }}
        >
          YouDrinkWeDrive is a professional service provider to ensure you are
          not behind the wheel under influence. We are a service company in the
          drink-drive category operating from Colombo and providing service
          throughout the Western Province in Sri Lanka. We provide fully
          trained, pleasant, fully vetted, and licensed drivers who pride
          themselves on their knowledge of the local area and outstanding
          customer relationships.
        </Typography>
        <List
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start", // Align items to the Left
          }}
        >
          <ListItem
            sx={{
              paddingY: 0.5,
            }}
          >
            <ListItemIcon sx={{ minWidth: "auto", paddingLeft: 1 }}>
              <CheckIcon sx={{ color: "green" }} />
            </ListItemIcon>
            <ListItemText
              primary="24/7 service"
              sx={{ textAlign: "left", fontSize: { xs: "14px", md: "16px" } }}
            />
          </ListItem>
          <ListItem
            sx={{
              paddingY: 0.5,
            }}
          >
            <ListItemIcon sx={{ minWidth: "auto", paddingLeft: 1 }}>
              <CheckIcon sx={{ color: "green" }} />
            </ListItemIcon>
            <ListItemText
              primary="Professional and experienced drivers"
              sx={{ textAlign: "left", fontSize: { xs: "14px", md: "16px" } }}
            />
          </ListItem>
          <ListItem
            sx={{
              paddingY: 0.5,
            }}
          >
            <ListItemIcon sx={{ minWidth: "auto", paddingLeft: 1 }}>
              <CheckIcon sx={{ color: "green" }} />
            </ListItemIcon>
            <ListItemText
              primary="Competitive pricing"
              sx={{ textAlign: "left", fontSize: { xs: "14px", md: "16px" } }}
            />
          </ListItem>
          <ListItem
            sx={{
              paddingY: 0.5,
            }}
          >
            <ListItemIcon sx={{ minWidth: "auto", paddingLeft: 1 }}>
              <CheckIcon sx={{ color: "green" }} />
            </ListItemIcon>
            <ListItemText
              primary="Reliable and friendly service"
              sx={{ textAlign: "left", fontSize: { xs: "14px", md: "16px" } }}
            />
          </ListItem>
        </List>
        <Stack
          direction="column"
          alignItems="flex-start" // Align items to the left
          spacing={2} // Add spacing between the buttons if needed
        >
          <Button
            variant="contained"
            sx={{
              marginTop: "20px",
              backgroundColor: "#FFCF43",
              width: "200px",
              transition: "transform 0.3s ease, background-color 0.3s ease",
              transform: "translateX(20px)", // Move 20px to the right
              "&:hover": {
                backgroundColor: "#FFD8A3", // Light yellow color on hover
              },
            }}
            onClick={() =>{
              document.getElementById("news-bar").scrollIntoView({ behavior: "smooth" });
            }}
          >
            Place a Booking
          </Button>
          <Button
            disableRipple
            sx={{
              padding: 0, // Remove default padding
              backgroundColor: "transparent", // Ensure the background is transparent
              "&:focus": {
                outline: "none", // Remove the focus outline
                boxShadow: "none", // Remove the focus shadow
              },
              "&:active": {
                backgroundColor: "transparent", // Remove background color on click
                boxShadow: "none", // Remove shadow on active state
              },
              transform: "translateX(20px)", // Move 20px to the right
            }}
            onClick={() =>
              (window.location.href =
                "https://play.google.com/store/apps/details?id=com.app.youdrinkwedrive")
            }
          >
            <img src="assets/images/play_store.png" alt="Google Play Store" />
          </Button>
        </Stack>
      </Stack>

      <Box width={{ xs: "100%", sm: "80px", md: "100px" }} />

      <Stack
        direction="column"
        spacing={2}
        sx={{
          width: { xs: "100%", sm: "80%", md: "50%" }, // Adjust width for different screen sizes
          alignItems: "center",
        }}
      >
        <Box
          component="img"
          src="image.png"
          sx={{
            width: { xs: "100%", sm: "250px", md: "500px" },
            height: { xs: "auto", sm: "150px", md: "300px" },
            objectFit: "cover", // Ensure image covers the area without distortion
          }}
        />
      </Stack>
    </Box>
  )
}

export default Mission
