import "./App.css"
import React, { useEffect, useContext } from "react"
import { BrowserRouter as Router, Route, Routes } from "react-router-dom"
import Cookies from "universal-cookie"
import { ThemeProvider, createTheme } from "@mui/material/styles"
import LoginPopup from "./popups/Login"
import Welcome from "./pages/Guest/Welcome"
import AuthContextProvider, { AuthContext } from "./context/AuthContext"
import Admin from "./pages/Admin"
import Client from "./pages/Client"
import Login from "./pages/Login"
import Driver from "./pages/Admin/Driver"
import Trip from "./pages/Admin/Trip"
import Payment from "./pages/Admin/Payment"
import Master from "./pages/Admin/Master"
import Options from "./pages/Admin/Options"
import User from "./pages/Admin/Users"
import Insights from "./pages/Admin/Insights"
import { CircularProgress } from "@mui/material"
import Content from "./pages/Guest/Welcome/Content"
import Rates from "./pages/Guest/Rates"
import AboutUs from "./pages/Guest/AboutUs"
import NavigationContextProvider from "./context/NavigationContext"
import TripDialogue from "./pages/Admin/Trip/TripDialogue"
import TripAddNew from "./pages/Admin/Trip/TripAddNew"
import DriverDialogue from "./pages/Admin/Driver/DriverDialogue"
import DriverAddNew from "./pages/Admin/Driver/DriverAddNew"
import PaymentDialogue from "./pages/Admin/Payment/PaymentDialogue"
import PaymentAddNew from "./pages/Admin/Payment/PaymentAddNew"
import Manager from "./pages/Manager"

import TripContextProvider from "./context/TripContext"
import PaymentContextProvider from "./context/PaymentContext"
import DriverContextProvider from "./context/DriverContext"

function App() {
  const { user, username, userType, login, logout } = useContext(AuthContext)
  const [loading, setLoading] = React.useState(false)
  const cookies = new Cookies()

  const theme = createTheme({
    // Define your theme settings here
  })

  useEffect(() => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
    }, 2000)
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      if (cookies.get("user") !== undefined) {
        await login(cookies.get("user").user)
        console.log("Cookie", cookies.get("user").user)
      }
      console.log("usertype", userType)
    }
    fetchData()
  }, [])

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh", // Full viewport height
        }}
      >
        <CircularProgress />
      </div>
    )
  }

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <AuthContextProvider>
          <Router>
            <Routes>
              <Route path="/" element={<Welcome />}>
                <Route path="rates" element={<Rates />} />
                <Route path="aboutus" element={<AboutUs />} />
                <Route path="" element={<Content />} />
              </Route>
              <Route
                path="/wp-admin"
                element={
                  <NavigationContextProvider>
                    <Admin />
                  </NavigationContextProvider>
                }
              >
                <Route
                  path="trip"
                  element={
                    <TripContextProvider>
                      <Trip />
                    </TripContextProvider>
                  }
                />
                <Route
                  path="trip/post/:id"
                  element={
                    <TripContextProvider>
                      <TripDialogue />
                    </TripContextProvider>
                  }
                />
                <Route
                  path="trip/addnew"
                  element={
                    <TripContextProvider>
                      <TripAddNew />
                    </TripContextProvider>
                  }
                />
                <Route
                  path="driver"
                  element={
                    <DriverContextProvider>
                      <Driver />
                    </DriverContextProvider>
                  }
                />
                <Route
                  path="driver/post/:id"
                  element={
                    <DriverContextProvider>
                      <DriverDialogue />
                    </DriverContextProvider>
                  }
                />
                <Route
                  path="driver/addnew"
                  element={
                    <DriverContextProvider>
                      <DriverAddNew />
                    </DriverContextProvider>
                  }
                />
                <Route
                  path="payment"
                  element={
                    <PaymentContextProvider>
                      <Payment />
                    </PaymentContextProvider>
                  }
                />
                <Route
                  path="payment/addnew"
                  element={
                    <PaymentContextProvider>
                      <PaymentAddNew />
                    </PaymentContextProvider>
                  }
                />
                <Route
                  path="payment/post/:id"
                  element={
                    <PaymentContextProvider>
                      <PaymentDialogue />
                    </PaymentContextProvider>
                  }
                />
                <Route path="master" element={<Master />} />
                <Route path="options" element={<Options />} />
                <Route path="users" element={<User />} />
                <Route path="insights" element={<Insights />} />
              </Route>
              <Route path="/wp-manager" element={<Manager />}>
                <Route
                  path="trip"
                  element={
                    <TripContextProvider>
                      <Trip />
                    </TripContextProvider>
                  }
                />
                <Route
                  path="trip/post/:id"
                  element={
                    <TripContextProvider>
                      <TripDialogue />
                    </TripContextProvider>
                  }
                />
                <Route
                  path="trip/addnew"
                  element={
                    <TripContextProvider>
                      <TripAddNew />
                    </TripContextProvider>
                  }
                />
              </Route>
              <Route path="/wp-login" element={<Login />} />
              <Route path="/appclient" element={<Client />} />
            </Routes>
          </Router>
        </AuthContextProvider>
      </ThemeProvider>
    </div>
  )
}

export default App
