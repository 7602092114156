import React, { useState } from "react"
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  TextField,
  MenuItem,
  IconButton,
  Box,
  Typography,
  Button,
  Select,
  InputLabel,
  FormControl,
} from "@mui/material"
import { Add as AddIcon, Delete as DeleteIcon } from "@mui/icons-material"

const initialUsers = [
  { id: 1, name: "John Doe", email: "john@example.com", role: "Admin" },
  { id: 2, name: "Jane Smith", email: "jane@example.com", role: "Officer" },
]

const UserManagement = () => {
  const [users, setUsers] = useState(initialUsers)
  const [searchText, setSearchText] = useState("")
  const [searchRole, setSearchRole] = useState("All")
  const [newUser, setNewUser] = useState({ name: "", email: "", role: "Admin" })

  const handleAddNewUser = () => {
    setUsers([...users, { ...newUser, id: users.length + 1 }])
    setNewUser({ name: "", email: "", role: "Admin" })
  }

  const handleDeleteUser = (id) => {
    setUsers(users.filter((user) => user.id !== id))
  }

  const handleSearchTextChange = (e) => {
    setSearchText(e.target.value)
  }

  const handleSearchRoleChange = (e) => {
    setSearchRole(e.target.value)
  }

  const handleRoleChange = (id, newRole) => {
    setUsers(
      users.map((user) => (user.id === id ? { ...user, role: newRole } : user))
    )
  }

  const filteredUsers = users.filter(
    (user) =>
      (user.name.toLowerCase().includes(searchText.toLowerCase()) ||
        user.email.toLowerCase().includes(searchText.toLowerCase())) &&
      (searchRole === "All" || user.role === searchRole)
  )

  return (
    <Box p={2}>
      <Typography variant="h5" gutterBottom>
        User Management
      </Typography>
      <Box mb={2} display="flex" alignItems="center" gap={2}>
        <Button variant="contained" color="primary" onClick={handleAddNewUser}>
          <AddIcon /> Add New
        </Button>
        <TextField
          label="Search"
          variant="outlined"
          size="small"
          value={searchText}
          onChange={handleSearchTextChange}
          sx={{ flex: 1 }}
        />
        <FormControl variant="outlined" size="small" >
          <InputLabel>Role</InputLabel>
          <Select
            value={searchRole}
            onChange={handleSearchRoleChange}
            label="Role"
          >
            <MenuItem value="All">All</MenuItem>
            <MenuItem value="Admin">Admin</MenuItem>
            <MenuItem value="Officer">Officer</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>User Name</TableCell>
            <TableCell>Email Address</TableCell>
            <TableCell>Role</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredUsers.map((user) => (
            <TableRow key={user.id}>
              <TableCell>{user.name}</TableCell>
              <TableCell>{user.email}</TableCell>
              <TableCell>
                <Select
                  value={user.role}
                  onChange={(e) => handleRoleChange(user.id, e.target.value)}
                >
                  <MenuItem value="Admin">Admin</MenuItem>
                  <MenuItem value="Officer">Officer</MenuItem>
                </Select>
              </TableCell>
              <TableCell>
                <IconButton onClick={() => handleDeleteUser(user.id)}>
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  )
}

export default UserManagement
