import { create } from "@mui/material/styles/createTransitions"
import { createContext, useState, useMemo } from "react"

export const TripContext = createContext(null)

export default function NavigationContextProvider(props) {
  const [rowData, setRowData] = useState([])

  const contextValue = useMemo(
    () => ({
      rowData,
      setRowData,
    }),
    [rowData]
  )

  // const contextValue = {
  //   rowData,
  //   setRowData,
  // }
  return (
    <TripContext.Provider value={contextValue}>
      {props.children}
    </TripContext.Provider>
  )
}


