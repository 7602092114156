import React, { useEffect, useRef } from "react"
import TextField from "@mui/material/TextField"
import { loadGoogleMapsScript } from "../googleMapsLoader"

const LocationButton = ({
  error,
  helperText,
  value,
  setValue,
  label,
  width,
  isPickup,
}) => {
  const inputRef = useRef(null)
  const autocompleteObjRef = useRef(null)

  useEffect(() => {
    const initAutocomplete = () => {
      if (window.google && window.google.maps) {
        const autocomplete = new window.google.maps.places.Autocomplete(
          inputRef.current, // Use the actual input element
          {
            // types: ["establishment"],
            types: isPickup ? ["establishment"] : [], // Only allow addresses

            componentRestrictions: { country: "lk" },
          }
        )
        autocompleteObjRef.current = autocomplete
        autocomplete.addListener("place_changed", handlePlaceChanged)
        console.log("Autocomplete initialized")
      } else {
        console.error("Google Maps API not loaded")
      }
    }

    const handlePlaceChanged = () => {
      if (autocompleteObjRef.current) {
        const place = autocompleteObjRef.current.getPlace()
        if (place && place.geometry) {
          const placeName = place.name || "" // Place name
          const addressComponents = place.address_components || [] // Address details

          console.log("Address Components:", addressComponents)

          // Extract only the relevant address parts (e.g., street, city, country)
          const relevantAddressParts = addressComponents
            .filter((component) =>
              [
                "route",
                "locality",
                "administrative_area_level_1",
                "country",
              ].includes(component.types[0])
            )
            .map((component) => component.long_name)

          // Remove duplicates while keeping order
          const uniqueAddressParts = [
            ...new Set([placeName, ...relevantAddressParts]),
          ]

          // Combine into a single string
          const placeDetails = uniqueAddressParts.join(", ")

          console.log("Place Details:", placeDetails)
          setValue(placeDetails) // Set the value in the input field
        } else {
          console.log("No place details found")
        }
      } else {
        console.error("Autocomplete object is not available")
      }
    }

    // const handlePlaceChanged = () => {
    //   if (autocompleteObjRef.current) {
    //     const place = autocompleteObjRef.current.getPlace()
    //     if (place && place.geometry) {
    //       const placeName = place.name || "" // Place name
    //       const addressComponents = place.address_components || [] // Address details

    //       // Extract only the relevant address parts (e.g., street, city, country)
    //       const relevantAddressParts = addressComponents
    //         .filter((component) =>
    //           [
    //             "route",
    //             "locality",
    //             "administrative_area_level_1",
    //             "country",
    //           ].includes(component.types[0])
    //         )
    //         .map((component) => component.long_name)
    //         .join(", ")
    //       console.log("Address Components:", addressComponents)
    //       // Combine the place name with the relevant address
    //       const placeDetails = `${placeName}, ${relevantAddressParts}`

    //       console.log("Place Details:", placeDetails)
    //       setValue(placeDetails) // Set the value in the input field
    //     } else {
    //       console.log("No place details found")
    //     }
    //   } else {
    //     console.error("Autocomplete object is not available")
    //   }
    // }

    loadGoogleMapsScript()
      .then(initAutocomplete)
      .catch((error) => {
        console.error("Error loading Google Maps script:", error)
      })
  }, [setValue])

  const style = document.createElement("style")
  style.innerHTML = `
      .pac-container {
        background-color: #f5f5f5 !important;
        border-radius: 8px !important;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2) !important;
      }
      .pac-item {
        font-size: 14px !important;
        padding: 8px 12px !important;
        color: #333 !important;
      }
      .pac-item:hover {
        background-color: #EEEE43 !important;
      }
      .pac-item-selected {
        background-color: #EEEE43 !important;
      }
      .pac-item-query {
        font-weight: bold !important;
        color: #000 !important;
      }
      .pac-matched {
        font-weight: bold !important;
        color: #FFAA43 !important;
      }
    `
  document.head.appendChild(style)

  return (
    <TextField
      fullWidth
      label={label}
      variant="outlined"
      value={value}
      inputRef={inputRef} // Ensure the ref is passed to the input element
      onChange={(event) => setValue(event.target.value)}
      error={error}
      helperText={helperText}
      InputProps={{
        sx: {
          width: { xs: "100%", sm: "100%", md: width }, // Full width on mobile and tablet, 300px on larger screens
          maxWidth: "100%", // Ensures the width doesn't exceed the container
          backgroundColor: "white",
        },
      }}
    />
  )
}

export default LocationButton
