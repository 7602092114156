import React, { useState, useEffect } from "react"
import Autocomplete from "@mui/material/Autocomplete"
import TextField from "@mui/material/TextField"
import api from "../../../apiConfig"
import debounce from "lodash/debounce"
import CircularProgress from "@mui/material/CircularProgress"

const SearchableDropdown = ({
  label,
  endpoint,
  onSelectionChange,
  value = "",
  disabled,
}) => {
  const [options, setOptions] = useState([])
  const [inputValue, setInputValue] = useState("")
  const [loading, setLoading] = useState(false)

  // Debounced fetch function
  const debouncedFetchOptions = debounce(async (searchText) => {
    setLoading(true)
    try {
      const response = await api.get("/api/v1/driver/list", {
        params: { searchText: searchText, page: 0, size: 10 },
      })
      console.log("Response:", response.data.data.dataList)
      if (response.data.status === 200) {
        setOptions(response.data.data.dataList || [])
      } else {
        setOptions([])
      }
    } catch (error) {
      console.error("Error fetching options", error)
      setOptions([])
    } finally {
      setLoading(false)
    }
  }, 300) // Debounce for 300ms

  useEffect(() => {
    if (inputValue.length > 0) {
      debouncedFetchOptions(inputValue)
    } else {
      setOptions([])
    }
  }, [inputValue])

  return (
    <Autocomplete
      freeSolo
      value={value || ""} // Ensure value is either the provided value or an empty string
      options={options} // Options are provided here
      getOptionLabel={(option) => {
        // Check if the option exists in the options array or if it's an empty string
        if (typeof option === "string" || !option) {
          return option // If option is a string (inputValue) or empty, return it directly
        }

        // Find the corresponding option in the options array
        const foundOption = options.find(
          (opt) => opt.name === option.name && opt.phone === option.phone
        )

        // If found, return the formatted label; otherwise, return an empty string or a fallback
        return foundOption ? `${foundOption.name} (${foundOption.phone})` : ""
      }}
      inputValue={inputValue || ""} // Ensure inputValue is never null
      loading={loading}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue)
      }}
      onChange={(event, newValue) => {
        onSelectionChange(newValue)
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          value={value}
          variant="outlined"
          size="small"
          disabled={disabled}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  )
}

export default SearchableDropdown
