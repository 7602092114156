import React, { useState } from "react"
import {
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material"
import api from "../../../apiConfig"

const Insights = () => {
  const [startDate, setStartDate] = useState("")
  const [endDate, setEndDate] = useState("")
  const [summaryData, setSummaryData] = useState({
    totalTripValue: 0,
    totalCompanyCharges: 0,
    totalTrips: 0,
    totalSettledCharges: 0,
    settlementCount: 0,
  })
  const [loading, setLoading] = useState(false)

  const handleSearch = async () => {
    try {
      setLoading(true)
      
      const response = await api.get("/api/v1/insight/general", {
        params: {
          startDate: startDate,
          endDate: endDate,
        },
      })

      if (response.status === 201) {
        setSummaryData(response.data.data)
      }
    } catch (error) {
      console.error("Error fetching insights:", error)
    } finally {
      setLoading(false)
    }
  }

  console.log("Summary Data", summaryData)
  return (
    <div>
      {/* Time Range Selectors */}
      <TextField
        label="Start Date"
        type="datetime-local"
        value={startDate}
        onChange={(e) => setStartDate(e.target.value)}
        InputLabelProps={{ shrink: true }}
        sx={{ marginRight: 2 }}
      />
      <TextField
        label="End Date"
        type="datetime-local"
        value={endDate}
        onChange={(e) => setEndDate(e.target.value)}
        InputLabelProps={{ shrink: true }}
        sx={{ marginRight: 2 }}
      />
      <Button variant="contained" onClick={handleSearch} disabled={loading}>
        {loading ? "Loading..." : "Search"}
      </Button>

      {/* Summary Table */}
      {/* {summaryData && ( */}
        <TableContainer component={Paper} sx={{ marginTop: 4 }}>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>
                  <strong>Total Trip Cost</strong>
                </TableCell>
                <TableCell>Rs. {summaryData.totalTripValue || 0}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <strong>Company Charge</strong>
                </TableCell>
                <TableCell>Rs. {summaryData.totalCompanyCharges || 0}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <strong>Total Trips</strong>
                </TableCell>
                <TableCell>{summaryData.totalTrips || 0}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <strong>Total Settlements</strong>
                </TableCell>
                <TableCell>Rs. {summaryData.totalSettledCharges || 0}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <strong>Settlement Count</strong>
                </TableCell>
                <TableCell>{summaryData.settlementCount || 0}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      {/* )} */}
    </div>
  )
}

export default Insights
