import React, { useState, useEffect } from "react"
import { Box, Typography, CardMedia } from "@mui/material"

const testimonials = [
  {
    imgSrc: "assets/images/person1.png",
    name: "Jagath  Kumburu Gamage",
    designation: "Director , OVIKLO International ( Pvt ) Ltd.",
    feedback:
      "Very professional chauffeurs and your service delivers value for money. Thanks for bringing me home safely. Your chauffeurs are super punctual, systematic & very polite. I am sure who use them once will use them again and again due to excellent customer service.",
  },
  {
    imgSrc: "assets/images/person2.png",
    name: "Mr.Rukmal Thilakarathne",
    designation: "A.Baur & Co.(pvt) Ltd.",
    feedback:
      "Excellent service and well focused on customer satisfaction. Youdrinkwedrive happens to be my 1st choice in need of driving assistance.",
  },
  {
    imgSrc: "assets/images/person3.png",
    name: "Mr.Wijitha Wimalasena",
    designation: "Divisional manager, MAS intimates",
    feedback:
      "Very impressive service provider in a timely needed assistance in preventing us behind the wheel under influence. Their discipline in driving with utmost care and cautiousness is commendable...",
  },
]

const Compliments = () => {
  const [currentIndex, setCurrentIndex] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length)
    }, 8000) // Change every 5 seconds

    return () => clearInterval(interval)
  }, [])

  return (
    <Box
      sx={{
        padding: { xs: "10px", sm: "20px" },
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        position: "relative",
        overflow: "hidden",
        // margin: "0 auto",
        mb: 0,
        minHeight: "400px",
        backgroundColor: "#303030",
        width: "100%",
      }}
    >
      <Typography
        variant="h4"
        sx={{ marginBottom: 3, textAlign: "center", color: "#FFFFFF" }}
      >
        What People Say About Us
      </Typography>
      <Box
        sx={{
          width: "100%",
          maxWidth: "600px", // Adjust to desired max width
          height: "350px",
          position: "relative",
        }}
      >
        {testimonials.map((testimonial, index) => (
          <Box
            key={index}
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              opacity: currentIndex === index ? 1 : 0,
              transition: "opacity 2s ease-in-out",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              textAlign: "center",
              //   backgroundColor: "rgba(0, 0, 0, 0.4)",
              padding: "20px",
              boxSizing: "border-box",
              zIndex: currentIndex === index ? 1 : 0,
              visibility: currentIndex === index ? "visible" : "hidden",
            }}
          >
            <CardMedia
              component="img"
              alt={testimonial.name}
              height="100"
              image={testimonial.imgSrc}
              sx={{ width: "100px", borderRadius: "50%", marginBottom: 2 }}
            />
            <Typography
              variant="body1"
              sx={{ fontWeight: "bold", color: "white" }}
            >
              {testimonial.name}
            </Typography>
            <Typography
              variant="body2"
              color="lightgray"
              sx={{ marginBottom: 2 }}
            >
              {testimonial.designation}
            </Typography>
            <Typography variant="body2" color="white">
              {testimonial.feedback}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  )
}

export default Compliments
