import * as React from "react"
import { useContext } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  MenuItem,
  Box,
  Typography,
  Button,
  Toolbar,
  Icon,
  IconButton,
} from "@mui/material"
import BackIcon from "@mui/icons-material/ArrowBack"
import { NavigationContext } from "../../../context/NavigationContext"
import api from "../../../apiConfig"

export default function TripAddNew() {
  const location = useLocation()
  // Extract base route from the current location (either wp-admin or wp-manager)
  const baseRoute = location.pathname.includes("wp-admin")
    ? "wp-admin"
    : "wp-manager"

  const createData = [
    {
      id: "client_name",
      type: "string",
      numeric: false,
      disabled: false,
      label: "Client Name",
    },
    {
      id: "client_email",
      type: "string",
      numeric: false,
      disabled: false,
      label: "Client Email",
    },
    {
      id: "client_phone",
      type: "string",
      numeric: false,
      disabled: false,
      label: "Client Phone",
    },
    {
      id: "pickup_location",
      type: "string",
      numeric: false,
      disabled: false,
      label: "Pickup Location",
    },
    {
      id: "destination",
      type: "string",
      numeric: false,
      disabled: false,
      label: "Destination",
    },
    {
      id: "pickup_time",
      type: "datetime",
      numeric: false,
      disabled: false,
      label: "Pickup Time",
    },
    {
      id: "promo_code",
      type: "string",
      numeric: false,
      disabled: false,
      label: "Promo Code",
    },
  ]

  const navigate = useNavigate()
  const [editedData, setEditedData] = React.useState({})

  const handleSaveClick = () => {
    console.log("Edited Data", editedData)
    api.post("/api/v1/trip", editedData).then((response) => {
      if (response.data.status !== 200) {
        console.log("Error adding trip", response)
        return
      } else {
        console.log("Trip added!", response)
        navigate(`/${baseRoute}/trip`)
      }
    })
  }

  const handleChange = (event) => {
    const { name, value } = event.target
    setEditedData({ ...editedData, [name]: value })
  }

  const renderInputField = (key) => {
    const headCell = createData.find((cell) => cell.id === key)
    if (!headCell) return null

    switch (headCell.type) {
      case "string":
        return (
          <TextField
            type="text"
            name={key}
            value={editedData[key] || ""}
            onChange={handleChange}
            fullWidth
            variant="outlined"
            size="small"
          />
        )
      case "datetime":
        return (
          <TextField
            type="datetime-local"
            name={key}
            value={editedData[key] || ""}
            onChange={handleChange}
            fullWidth
            variant="outlined"
            size="small"
          />
        )
      case "enum":
        return (
          <TextField
            select
            name={key}
            value={editedData[key] || ""}
            onChange={handleChange}
            fullWidth
            variant="outlined"
            size="small"
          >
            {headCell.enum.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
        )
      default:
        return (
          <TextField
            type="text"
            name={key}
            disabled={headCell.disabled}
            value={editedData[key] || ""}
            onChange={handleChange}
            fullWidth
            variant="outlined"
            size="small"
          />
        )
    }
  }

  if (!createData) {
    return <Typography>No data available to add a new trip.</Typography>
  }

  return (
    <Box>
      <Toolbar>
        <Button color="inherit" onClick={() => navigate(`/${baseRoute}/trip`)}>
          <BackIcon />
        </Button>

        <Typography variant="h6" gutterBottom>
          Add New Trip
        </Typography>
      </Toolbar>
      {/* <Typography variant="h6" gutterBottom>
        Add New Trip
      </Typography> */}
      <Table>
        <TableBody>
          {createData.map((cell) => (
            <TableRow key={cell.id}>
              <TableCell component="th" scope="row">
                {cell.label}
              </TableCell>
              <TableCell>{renderInputField(cell.id)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Box mt={2}>
        <Button variant="contained" color="primary" onClick={handleSaveClick}>
          Save
        </Button>
      </Box>
    </Box>
  )
}
