import React from "react"
import { Stack, Typography, Box } from "@mui/material"

const AssignedJobs = ({ jobs }) => {
  if (jobs.length === 0) {
    return (
      <Typography variant="h6" sx={{ color: "black" }}>
        No assigned jobs
      </Typography>
    )
  }
  return (
    <Stack spacing={2}>
      {jobs.map((job) => (
        <Box
          key={job.id}
          sx={{
            backgroundColor: "white", // White background
            padding: 3,
            borderRadius: 2,
          }}
        >
          <Typography variant="h6">
            Pickup Location: {job.pickupLocation}
          </Typography>
          <Typography variant="body1">
            Pickup Time: {new Date(job.pickupTime).toLocaleString()}
          </Typography>
        </Box>
      ))}
    </Stack>
  )
}

export default AssignedJobs
