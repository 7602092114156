import { Typography } from "@mui/material"
import React from "react"

const Vision = () => {
  return (
    <div
      style={{
        backgroundImage: "url('assets/images/img.png')",
        backgroundSize: "cover",
        backgroundPosition: "center",
        padding: "20px",
        minHeight: "100vh",
        display: "flex",
        alignItems: "center", // Center vertically
        justifyContent: "center", // Center horizontally
        color: "white",
      }}
    >
      <div
        style={{
          backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent background for highlighting
          padding: "20px",
          borderRadius: "8px", // Add some border radius to soften the box
          maxWidth: "600px", // Limit the width of the content
          textAlign: "center", // Center align the text
        }}
      >
        <Typography variant="h2" sx={{
          fontSize:"2rem"
        }} >Enjoy your drink, yet never risk it …</Typography>
        <p>
          Be smart and decide right; never to worry over suspending of driving
          license or leaving your car unattended. Our professional chauffeurs
          will bring you in your own vehicle to your doorstep at a reasonable
          cost without having you to drink and drive!
        </p>
      </div>
    </div>
  )
}

export default Vision
