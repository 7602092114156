import React from "react"
import { Box, Dialog, Typography, IconButton, Divider } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"

const TermsPopup = (props) => {
  const { onClose, open } = props

  const handleClose = () => {
    if (onClose) {
      onClose()
    }
  }

  return (
    <Dialog
      open={open||false}
      onClose={handleClose}
      fullWidth
      maxWidth = "md"
      PaperProps={{
        sx: {
          height: "80vh", // Set a max height for the dialog
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      <Box
        sx={{
          backgroundColor: "white",
          padding: 3,
          display: "flex",
          flexDirection: "column",
          height: "100%", // Full height for the box
        }}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6">Terms and Conditions</Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Divider sx={{ marginY: 2 }} />
        <Box
          sx={{
            flex: 1, // Flex to fill available space
            overflowY: "auto", // Scrollable content area
            fontSize: "0.875rem", // Adjust font size for readability
          }}
        >
          <Typography paragraph>
            <strong>Effective Date:</strong> 24th March, 2018
          </Typography>

          <Typography paragraph>
            <strong>Services:</strong> We provide exclusive chauffeur services,
            for you to travel in ease and comfort in your own vehicle. Our
            services are available exclusively from 7.00 p.m. to 12.00 a.m.
            However, special services can be provided on request and
            availability. We reserve the right to accept/cancel bookings. Our
            service will not be provided via express way routes.
          </Typography>

          <Typography paragraph>
            <strong>Bookings:</strong> Bookings are accepted via online booking
            or telephone. All bookings are confirmed via SMS with a booking
            reference number. You may make any changes to the booking, subject
            to at least 2 hours' prior notification to the management.
            Chauffeurs do not have the authority to alter the booked times, pick
            up or drop off destination or routes without the prior consent of
            the management. You would be charged an additional fee for
            deviations from the original booking instructions. Such additional
            payments should be made to the Chauffeur at the drop off location or
            at the end of the provision of services.
          </Typography>

          <Typography paragraph>
            <strong>Rates:</strong> We charge 2000/= as a call-up charge with
            complimentary 15 kms. Any additional distance will be charged Rs 50
            per km. We provide 15 minutes of complimentary waiting time from
            booking time, which is indicated to you via SMS with the chauffeur’s
            reached time. Any excess waiting time will be charged Rs 250/= per
            15-minute slot. The above rates do not include tolls, parking fees,
            fuel, meals, accommodation, entrance fees, or other incidental
            costs.
          </Typography>

          <Typography paragraph>
            <strong>Payments:</strong> Payment should be made at the end of
            service. Your final bill will be sent via SMS once the Chauffeur
            confirms the trip end. We accept cash payments only.
          </Typography>

          <Typography paragraph>
            <strong>Cancellation:</strong> A cancellation fee of Rs. 500 will be
            charged for less than 2 hours' notice. No refunds will be made after
            the Chauffeur has been dispatched for pickup. However, advance
            payments made will be set off against any future bookings subject to
            any deductions and will not be refundable.
          </Typography>

          <Typography paragraph>
            <strong>Chauffeurs:</strong> All chauffeurs possess a valid driving
            license, are well-trained, polite, and courteous. The management
            will inquire into any complaints related to the chauffeurs, if
            notified via email with details.
          </Typography>

          <Typography paragraph>
            <strong>Limitation of Liability:</strong> You are entirely
            responsible for the whereabouts and safekeeping of your personal
            belongings. We will accept no liability for loss or damage at any
            time under any circumstances. Our terms and conditions apply
            exclusively for the supply of the services mentioned herein and are
            not binding for additional services. In any event, we will not be
            liable, whether in tort, in contract, or otherwise, for any
            accident, delay, injury, irregularity, loss, or damage to persons or
            property for any kind whatsoever and howsoever arising (including
            but not limited to any negligence or breach of contract by us or the
            chauffeurs) caused or incurred during or in relation to the
            provision of service mentioned herein. We accept no liability for
            any business loss, which includes loss of contracts, loss of profit,
            loss of revenue, or loss of anticipated savings. We reserve the
            right to subcontract the provision of the services to our chosen
            third-party subcontractors, and our liability is limited to ensuring
            that we use due care and skill in the selection of the
            subcontractor. In any instance, our liability will be only up to the
            value paid for the services.
          </Typography>

          <Typography paragraph>
            <strong>Warranty:</strong> The client represents and warrants that
            he/she is the owner of the vehicle used for the provision of
            services or that he/she has obtained the consent and has the
            authority to use the vehicle for the provision of service and
            further represents and warrants that his/her vehicle is insured and
            possesses a valid revenue license.
          </Typography>

          <Typography paragraph>
            <strong>Indemnity:</strong> The client shall ensure the
            transportation of lawful properties. In the event of disruption, the
            organization's obligation shall not accompany.
          </Typography>

          <Typography paragraph>
            <strong>Unforeseen Circumstances:</strong> We will make every effort
            to arrive at your pickup location on time. However, allowances must
            be made for unforeseen circumstances such as traffic, road and
            weather conditions, illness, vehicle breakdowns, etc.
          </Typography>

          <Typography paragraph>
            <strong>Unacceptable Conduct:</strong> We reserve the right to
            terminate the provision of services at any time if the chauffeur is
            subject to threatening, abusive, unlawful, or inappropriate behavior
            or any other circumstances when a client’s conduct may prejudice the
            safety of themselves and the chauffeur.
          </Typography>

          <Typography paragraph>
            <strong>Personal Information:</strong> The personal information
            disclosed to us will be used for the purpose of providing the
            services only. Your personal information will not be given to any
            third party except where the purpose relates to fulfilling the
            obligations related to the services. We may retain your information
            for use in future bookings. If, at any time, you discover that the
            information held is incorrect, you may contact us to have the
            information corrected. We may be obliged to surrender your personal
            information to regulatory authorities and law enforcement officials
            in accordance with the applicable laws. Personal information may be
            disclosed to identify, contact, or bring legal action against anyone
            in the event of theft, illegal activity, or injury damage caused to
            any party, including the chauffeur.
          </Typography>

          <Typography paragraph>
            <strong>Force Majeure:</strong> We shall not be liable for any delay
            or failure of performance of our obligations under these terms and
            conditions for any cause beyond our reasonable control, including
            acts of god, governmental acts, war, fire, flood, explosion, or
            civil commotion, failure in information technology or
            telecommunication services, or any industrial action.
          </Typography>

          <Typography paragraph>
            <strong>Invalidity:</strong> In the event any provision of these
            terms and conditions is found by any court or competent authority to
            be invalid, unlawful, or unenforceable, that provision shall be
            deemed not to be a part of these terms and conditions. It shall not
            affect the enforceability of the remainder of the terms and
            conditions nor shall it affect the validity, lawfulness, or
            enforceability of that provision in any other applicable
            jurisdiction.
          </Typography>

          <Typography paragraph>
            <strong>Governing Law and Jurisdiction:</strong> The provisions of
            this Agreement shall be governed by and construed in accordance with
            the laws of Sri Lanka, and the Parties agree that the Courts of
            Colombo shall have exclusive jurisdiction in relation to all
            disputes or matters pertaining to or arising under or in relation to
            this Agreement.
          </Typography>

          <Typography paragraph>
            <strong>Acceptance:</strong> The use of our services is considered
            acceptance of these terms and conditions. Deviations from these
            conditions will be considered accepted only upon our written
            consent.
          </Typography>

          <Typography paragraph>
            <strong>Application and Compliance:</strong> This company is related
            to all directors, employees, officers, agents, advisors,
            consultants, chauffeurs, and riders of “YouDrink-WeDrive”.
          </Typography>
        </Box>
      </Box>
    </Dialog>
  )
}

export default TermsPopup
