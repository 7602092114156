import React from "react"
import ReactDOM from "react-dom/client"
import App from "./App"
import reportWebVitals from "./reportWebVitals"
import CssBaseline from "@mui/material/CssBaseline"
import { BrowserRouter } from "react-router-dom"
import AuthContextProvider, { AuthContext } from "./context/AuthContext"
import { HelmetProvider } from "react-helmet-async"

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
  <HelmetProvider>
    <AuthContextProvider>
      <App />
    </AuthContextProvider>
  </HelmetProvider>
)

// ;<React.StrictMode>
//   <CssBaseline />
//   <AuthContextProvider>
//     <App />
//   </AuthContextProvider>
// </React.StrictMode>
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
