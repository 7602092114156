import React from "react"
import { Box, Stack, useMediaQuery } from "@mui/material"
import Vision from "./Vision"
import Mission from "./Mission"
import ChooseUs from "./ChooseUs"
import NewsBar from "./NewsBar"
import Compliments from "./Compliments"

const Content = () => {
  // Media query for small screens
  // const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"))

  return (
    <Box
    >
      <Stack>
        <NewsBar />
        <Mission />
        <Vision />
        <ChooseUs />
        <Compliments />
      </Stack>
    </Box>
  )
}

export default Content
