import React from "react"
import { Box, Typography } from "@mui/material"

const ServiceBlock = ({ imgSrc, title, children }) => (
  <Box
    sx={{
      width: { xs: "100%", sm: "48%", md: "21%" }, // Adjust width for different screen sizes
      padding: 2,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      textAlign: "center",
      backgroundColor: "#fff",
      borderRadius: "8px",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    }}
  >
    <img
      src={imgSrc}
      alt={title}
      style={{
        width: "60px", // Smaller image size
        height: "auto",
        marginBottom: "10px",
      }}
    />
    <Typography variant="h6" sx={{ marginBottom: 1 }}>
      {title}
    </Typography>
    <Typography
      sx={{
        fontSize: { xs: "12px", sm: "14px" }, // Smaller font size
        color: "#666", // Light color for the text
      }}
    >
      {children}
    </Typography>
  </Box>
)

const WhyChooseUs = () => {
  return (
    <Box
      sx={{
        padding: { xs: "10px", sm: "50p 0px 50px 0px", md: "50px 0px 100px 0px" },
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Typography variant="h4" sx={{ marginBottom: 3 }}>
        Why Choose Us!
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          flexWrap: "wrap",
          gap: 2,
          justifyContent: "center",
        }}
      >
        <ServiceBlock
          imgSrc="assets/images/service1.png"
          title="Best in Class Service"
        >
          Professional chauffeurs ensure your safety till doorstep. No matter
          whether you have a drink with your friends at a bar at the end of a
          hectic day, "You Drink We Drive" is there for you. You'll never regret
          using our service to drive your car with competitive rates,
          professional drivers and friendly staff.
        </ServiceBlock>
        <ServiceBlock
          imgSrc="assets/images/service2.png"
          title="Fast and Convenient"
        >
          24x7 web or hotline reservation. Guess you have been out celebrating a
          birthday, anniversary or a business deal, or you have been out
          enjoying with your friends, make the smart decision to call or online
          book YouDrinkWeDrive. We are at the reach of your fingertips!
        </ServiceBlock>
        <ServiceBlock
          imgSrc="assets/images/service3.png"
          title="Reasonable Fare"
        >
          Estimate cost via rate calculator. We assure you a service worth the
          cost; components of total cost being elaborative and transparent.
        </ServiceBlock>
        <ServiceBlock
          imgSrc="assets/images/service4.png"
          title="Attractive Service"
        >
          We will keep your mood. Our professional and friendly drivers will
          always be at your command to provide a service of unparalleled
          satisfaction.
        </ServiceBlock>
      </Box>
    </Box>
  )
}

export default WhyChooseUs
