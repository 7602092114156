import * as React from "react"
import AppBar from "@mui/material/AppBar"
import Box from "@mui/material/Box"
import Toolbar from "@mui/material/Toolbar"
import IconButton from "@mui/material/IconButton"
import Typography from "@mui/material/Typography"
import Menu from "@mui/material/Menu"
import MenuIcon from "@mui/icons-material/Menu"
import Container from "@mui/material/Container"
import Button from "@mui/material/Button"
import MenuItem from "@mui/material/MenuItem"
import { useNavigate } from "react-router-dom"
import { Link } from "react-router-dom"
import { Stack } from "@mui/material"
import { Helmet } from "react-helmet-async"

// List of pages with internal or external links
const pages = [
  { name: "Home", link: "/", external: false },
  { name: "About Us", link: "/aboutus", external: false },
  { name: "Rates", link: "/rates", external: false },
]

function NavBar() {
  const [windowWidth, setWindowWidth] = React.useState(window.innerWidth)
  const navigate = useNavigate()
  const [anchorElNav, setAnchorElNav] = React.useState(null)

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget)
  }

  const handleCloseNavMenu = () => {
    setAnchorElNav(null)
  }

  // Track window size for responsive design
  React.useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth)
    }

    window.addEventListener("resize", handleResize)

    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  const customFontStyle = {
    fontFamily: "Inter",
    fontWeight: 500,
    fontSize: "15px",
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>Home | You Drink We Drive</title>
        <meta
          name="description"
          content="You Drink We Drive - Home, About Us, and Rates for a safer ride."
        />
        <link rel="canonical" href="/" />
      </Helmet>

      <AppBar
        position="static"
        sx={{
          backgroundColor: "white",
          width: "100%",
          margin: 0,
          padding: 0,
          boxShadow: "none",
        }}
        elevation={0}
      >
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            {/* Mobile Navbar */}
            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="menu"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon sx={{ color: "black" }} />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              >
                {pages.map((page) => (
                  <MenuItem key={page.name} onClick={() => navigate(page.link)}>
                    <Typography color={"black"} textAlign="center">
                      <Link to={page.link}>{page.name}</Link>
                    </Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>

            {/* Logo and Desktop Navbar */}
            <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
              <Link to="/" style={{ textDecoration: "none" }}>
                <Typography
                  variant="h1"
                  style={{
                    ...customFontStyle,
                    fontSize: "20px",
                    fontWeight: 800,
                  }}
                >
                  <img
                    src="youdrinkwedrivelogo.png"
                    alt="You Drink We Drive Logo"
                    width={50}
                    height={20}
                  />
                  <span style={{ color: "black" }}>You</span>
                  <span style={{ color: "#FFCF43" }}> Drink </span>
                  <span style={{ color: "black" }}>We</span>
                  <span style={{ color: "#FFCF43" }}> Drive </span>
                </Typography>
              </Link>
            </Box>

            {/* Desktop Navigation Menu */}
            <Box
              sx={{
                flexGrow: 1,
                display: { xs: "none", md: "flex" },
                justifyContent: "center",
              }}
            >
              <Stack direction="row" spacing={2}>
                {pages.map((page) => (
                  <Button
                    key={page.name}
                    onClick={() => navigate(page.link)}
                    sx={{
                      my: 2,
                      color: "black",
                      display: "block",
                      textTransform: "none",
                      padding: "10px 20px",
                    }}
                    style={customFontStyle}
                  >
                    {page.external ? (
                      <a
                        href={page.link}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ textDecoration: "none", color: "inherit" }}
                      >
                        {page.name}
                      </a>
                    ) : (
                      <Link
                        to={page.link}
                        style={{ textDecoration: "none", color: "inherit" }}
                      >
                        {page.name}
                      </Link>
                    )}
                  </Button>
                ))}
              </Stack>
            </Box>

            {/* Phone Number */}
            <Box sx={{ display: "flex", alignItems: "center", flexGrow: 1 }}>
              <Link to="callto:0777400040" style={{ textDecoration: "none" }}>
                <Typography
                  sx={{ color: "#000077", marginLeft: 2, fontSize: "20px" }}
                >
                  0777 4000 40
                </Typography>
              </Link>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>

      {/* Additional Meta Tags for SEO (can be dynamic based on route) */}
      <Helmet>
        <meta property="og:title" content="You Drink We Drive" />
        <meta
          property="og:description"
          content="You Drink We Drive offers reliable and safe transportation services."
        />
        <meta property="og:url" content="https://www.youdrinkwedrive.com" />
        <meta
          property="og:image"
          content="https://www.youdrinkwedrive.com/logo.png"
        />
        <meta name="robots" content="index, follow" />
      </Helmet>
    </React.Fragment>
  )
}

export default NavBar
