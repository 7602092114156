import React from "react"
import Navbar from "../../../components/NavBar"
import Footer from "../../../components/Footer"
import Content from "./Content"
import { Box } from "@mui/material"
import { Outlet } from "react-router-dom"
import { Helmet } from "react-helmet-async"

const Welcome = () => {
  return (
    <>
      <Helmet>
        <title>You Drink We Drive</title>
        <meta
          name="description"
          content="We drive our customers home safely in the comfort of their own cars when they are under the influence of alcohol. We are a service company in drink drive category operating from Colombo and providing service throughout the Western province in Sri Lanka"
        />
        <link rel="canonical" href="" />
      </Helmet>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
        }}
      >
        <Navbar />
        <Outlet />
        <Footer />
      </Box>
    </>
  )
}

export default Welcome
