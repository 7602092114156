import React, { useEffect, useState } from "react"
import { Typography } from "@mui/material"

const formatTimeFromSeconds = (seconds) => {
  const hours = Math.floor(seconds / 3600)
  const minutes = Math.floor((seconds % 3600) / 60)
  const secs = seconds % 60
  return `${hours ? hours + "h " : ""}${minutes ? minutes + "m " : ""}${secs}s`
}

const WaitingTimeComponent = ({
  waitingTimeSum = 0,
  waitingStartTime,
  isWaiting,
}) => {
  const [elapsedTime, setElapsedTime] = useState(waitingTimeSum)

  useEffect(() => {
    let interval = null

    if (isWaiting && waitingStartTime) {
      const startTimeInSeconds = Math.floor(
        (Date.now() - new Date(waitingStartTime)) / 1000
      )

      setElapsedTime(waitingTimeSum + startTimeInSeconds)
      
      interval = setInterval(() => {
        setElapsedTime((prevElapsedTime) => prevElapsedTime + 1)
      }, 1000)
    }

    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [isWaiting, waitingStartTime])

  useEffect(() => {
    if (!isWaiting) {
      setElapsedTime(waitingTimeSum)
    }
  }, [isWaiting, waitingTimeSum])

  return (
    <Typography variant="h6" gutterBottom>
      Waiting Time: {formatTimeFromSeconds(elapsedTime)}
    </Typography>
  )
}

export default WaitingTimeComponent
