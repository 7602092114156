import React, { useContext, useEffect } from "react"
import AuthContextProvider, { AuthContext } from "../../context/AuthContext"
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useNavigate,
} from "react-router-dom"
import Cookies from "universal-cookie"
import Login from "../Login"
import Drawer from "./Drawer"

const Home = () => {
  const { user, username, userType, login, logout } =
    React.useContext(AuthContext)

  const cookies = new Cookies()

  useEffect(() => {
    const fetchData = async () => {
      if (cookies.get("user") !== undefined) {
        await login(cookies.get("user").user)
        console.log("Cookie", cookies.get("user").user)
      }
      console.log("usertype", userType)
    }
    fetchData()
    console.log(userType)
  }, [])
  if (userType === "guest") {
    return <Navigate to="/wp-login" />
  }
  return <Drawer />
}

export default Home
