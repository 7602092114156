import React, { useContext } from "react"
import EnhancedTable from "./DriverTable"
import api from "../../../apiConfig"
import { NavigationContext } from "../../../context/NavigationContext"
import { DriverContext } from "../../../context/DriverContext"

const Driver = () => {
  const [count, setCount] = React.useState(0)
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const { setRowData } = useContext(DriverContext)

  function createDriverData(
    id,
    status,
    license,
    phone,
    phoneSecondary,
    name,
    email,
    address,
    companyCharge
  ) {
    return {
      id,
      status,
      license,
      phone,
      phoneSecondary,
      name,
      email,
      address,
      companyCharge,
    }
  }

  React.useEffect(() => {
    api
      .get("/api/v1/driver/list", {
        params: {
          searchText: "",
          page: page,
          size: rowsPerPage,
        },
      })
      .then((response) => {
        if (response.data.status === 200) {
          console.log("Drivers fetched!", response.data)
          setCount(response.data.data.count)
          setRowData(
            response.data.data.dataList.map((driver) =>
              createDriverData(
                driver.driverId,
                driver.status,
                driver.license,
                driver.phone,
                driver.phoneSecondary,
                driver.name,
                driver.email,
                driver.address,
                driver.companyCharge
              )
            )
          )
        } else {
          console.log("something went wrong!", response.data.data.dataList)
        }
      })
  }, [rowsPerPage, page])

  return (
    <EnhancedTable
      count={count}
      page={page}
      setPage={setPage}
      rowsPerPage={rowsPerPage}
      setRowsPerPage={setRowsPerPage}
    />
  )
}

export default Driver
