import React, { useEffect, useState } from "react"
import {
  TextField,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  IconButton,
  Box,
  Typography,
} from "@mui/material"
import {
  AddCircleSharp as AddIcon,
  Delete as DeleteIcon,
  CheckCircleSharp as Check,
} from "@mui/icons-material"
import api from "../../../apiConfig"

const Options = () => {
  const [promoCodes, setPromoCodes] = useState([
    { code: "", expiredDate: "", rate: "", fetched: false },
  ])

  const handlePromoCodeChange = (index, field, value) => {
    const updatedPromoCodes = [...promoCodes]

    if (field === "expiredDate") {
      // Format the date in "date"T"time" format
      const date = new Date(value).toISOString()
      updatedPromoCodes[index][field] = date
    } else if (field === "rate") {
      // Ensure the rate is a decimal
      updatedPromoCodes[index][field] = parseFloat(value)
    } else {
      updatedPromoCodes[index][field] = value
    }

    setPromoCodes(updatedPromoCodes)
  }

  const addPromoCode = () => {
    setPromoCodes([...promoCodes, { code: "", expiredDate: "", rate: "" }])
  }

  const removePromoCode = (index) => {
    console.log(promoCodes[index])
    const updatedPromoCodes = promoCodes.filter((_, i) => i !== index)
    setPromoCodes(updatedPromoCodes)

    if (promoCodes[index].fetched) {
      api
        .delete(`/api/v1/promo/delete`, {
          params: {
            id: promoCodes[index].code,
          },
        })
        .then((response) => {
          if (response.data.status === 204) {
            console.log("Promo Code deleted!", response.data)
          } else {
            console.log("Something went wrong!", response.data)
          }
        })
    }
  }

  const savePromoCode = (index) => {
    const newPromoCode = promoCodes[index]
    console.log("New Promo Code", newPromoCode)
    if(!newPromoCode.code || !newPromoCode.expiredDate || !newPromoCode.rate) {
      alert("Please fill all fields")
      return
    }
    api.post("/api/v1/promo", newPromoCode).then((response) => {
      if (response.data.status === 201) {
        console.log("Promo Code saved!", response.data)
        setPromoCodes([
          ...promoCodes.slice(0, promoCodes.length - 1),
          { ...newPromoCode, fetched: true },
        ])
      } else {
        console.log("Something went wrong!", response.data)
      }
    })
  }

  useEffect(() => {
    api.get("/api/v1/promo/all").then((response) => {
      if (response.data.status === 200) {
        console.log("Options fetched!", response.data)

        const fetchedPromoCodes = response.data.data.map((promo) => ({
          code: promo.code,
          expiredDate: promo.expiredDate,
          rate: promo.rate,
          fetched: true,
        }))
        console.log("Promo Codes", fetchedPromoCodes)
        setPromoCodes(fetchedPromoCodes)
        // setFormValues(response.data.data)
      } else {
        console.log("Something went wrong!", response.data)
      }
    })
  }, [])

  return (
    <Box p={2}>
      <Typography variant="h6" gutterBottom>
        Promo Codes
      </Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Code</TableCell>
            <TableCell>Expire Date</TableCell>
            <TableCell>Discount Percentage</TableCell>
            <TableCell>Actions</TableCell>
            <TableCell>
              {" "}
              <IconButton onClick={addPromoCode}>
                <AddIcon
                  sx={{
                    color: "lightblue",
                  }}
                />
              </IconButton>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {promoCodes.map((promo, index) => (
            <TableRow key={index}>
              <TableCell>
                <TextField
                  fullWidth
                  variant="outlined"
                  size="small"
                  disabled={promo.fetched}
                  value={promo.code}
                  onChange={(e) =>
                    handlePromoCodeChange(index, "code", e.target.value)
                  }
                />
              </TableCell>
              <TableCell>
                <TextField
                  fullWidth
                  variant="outlined"
                  size="small"
                  type="date"
                  disabled={promo.fetched}
                  value={
                    promo.expiredDate
                      ? new Date(promo.expiredDate).toISOString().split("T")[0]
                      : ""
                  }
                  onChange={(e) =>
                    handlePromoCodeChange(index, "expiredDate", e.target.value)
                  }
                />
              </TableCell>
              <TableCell>
                <TextField
                  fullWidth
                  variant="outlined"
                  size="small"
                  type="number"
                  disabled={promo.fetched}
                  // limit from 0 to 100
                  inputProps={{ min: 0, max: 100 }}
                  value={promo.rate}
                  onChange={(e) =>
                    handlePromoCodeChange(index, "rate", e.target.value)
                  }
                />
              </TableCell>
              <TableCell>
                <IconButton onClick={() => removePromoCode(index)}>
                  <DeleteIcon
                    sx={{
                      color: "pink",
                    }}
                  />
                </IconButton>
                {!promo.fetched && (
                  <IconButton onClick={() => savePromoCode(index)}>
                    <Check
                      sx={{
                        color: "lightgreen",
                      }}
                    />
                  </IconButton>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Box
        sx={{
          height: 20,
        }}
      />
    </Box>
  )
}

export default Options
