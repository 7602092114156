import * as React from "react"
import Avatar from "@mui/material/Avatar"
import Button from "@mui/material/Button"
import CssBaseline from "@mui/material/CssBaseline"
import TextField from "@mui/material/TextField"
import FormControlLabel from "@mui/material/FormControlLabel"
import Checkbox from "@mui/material/Checkbox"
import Link from "@mui/material/Link"
import Grid from "@mui/material/Grid"
import Box from "@mui/material/Box"
import LockOutlinedIcon from "@mui/icons-material/LockOutlined"
import Typography from "@mui/material/Typography"
import Container from "@mui/material/Container"
import { createTheme, ThemeProvider } from "@mui/material/styles"
import { useNavigate, Navigate } from "react-router-dom"
import Cookies from "js-cookie"
import api from "../../apiConfig"
import { AuthContext } from "../../context/AuthContext"
import { Visibility, VisibilityOff } from "@mui/icons-material"
import { IconButton, InputAdornment } from "@mui/material"

const defaultTheme = createTheme()

export default function LogIn() {
  const { userType, login } = React.useContext(AuthContext)
  const token = Cookies.get("Authorization")
  const navigate = useNavigate()

  const [showPassword, setShowPassword] = React.useState(false)

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  // Function to decode JWT
  const decodeJwt = (token) => {
    const base64Url = token.split(".")[1] // Get the payload part
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/") // Replace Base64 URL format with Base64
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map((c) => "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2))
        .join("")
    )
    return JSON.parse(jsonPayload) // Parse the decoded payload
  }

  // Check for token and login the user if token is available
  React.useEffect(() => {
    if (token) {
      api.defaults.headers.common["Authorization"] = `Bearer ${token}`
      const decodedToken = decodeJwt(token)

      // Login context call
      login({
        username: decodedToken.sub,
        user_type:
          decodedToken.authorities[0]?.authority === "ROLE_ADMIN"
            ? "admin"
            : decodedToken.authorities[0]?.authority === "ROLE_MANAGER"
            ? "manager"
            : decodedToken.authorities[0]?.authority === "ROLE_DRIVER"
            ? "driver"
            : "customer",
        customer_NIC: "123456",
      })

      // Keep the user on the same route
      const currentPath = window.location.pathname
      if (currentPath.startsWith("/wp-admin")) {
        navigate(currentPath) // Navigate to the same route on refresh
      }
    }
  }, [token, login, navigate])

  const handleSubmit = (event) => {
    event.preventDefault()
    const data = new FormData(event.currentTarget)

    api
      .post("/login", {
        username: data.get("email"),
        password: data.get("password"),
      })
      .then((response) => {
        if (response.status === 200) {
          console.log("kkll", response)
          const token = response.headers["authorization"].replace("Bearer ", "")
          api.defaults.headers.common["Authorization"] = `Bearer ${token}`
          Cookies.set("Authorization", token, { expires: 7 })

          // Decode the JWT token to extract user info
          const decodedToken = decodeJwt(token)
          login({
            username: decodedToken.sub,
            user_type:
              decodedToken.authorities[0]?.authority === "ROLE_ADMIN"
                ? "admin"
                : decodedToken.authorities[0]?.authority === "ROLE_MANAGER"
                ? "manager"
                : decodedToken.authorities[0]?.authority === "ROLE_DRIVER"
                ? "driver"
                : "customer",
            customer_NIC: "123456",
          })
        } else {
          alert("Invalid credentials")
        }
      })
      .catch((error) => {
        console.log(error)
        alert("Invalid credentials")
      })
  }

  // If user is logged in as admin or driver, redirect to the appropriate path
  if (userType === "admin") {
    return (
      <Navigate
        to={
          window.location.pathname.startsWith("/wp-admin")
            ? window.location.pathname
            : "/wp-admin/trip"
        }
      />
    )
  } else if (userType === "manager") {
    return <Navigate to="/wp-manager/trip" />
  } else if (userType === "driver") {
    return <Navigate to="/appclient" />
  }

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1 }} src={"youdrinkwedrivelogo.png"} />
          {/* <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar> */}
          <Typography component="h1" variant="h5">
            Log in
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="User Name"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type={!showPassword ? "password" : "text"}
              id="password"
              autoComplete="current-password"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{
                mt: 3,
                mb: 2,
                backgroundColor: "#FFCF43",
                "&:hover": {
                  backgroundColor: "#FFD8A3", // Light yellow color on hover
                },
              }}
            >
              Log In
            </Button>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  )
}
