import * as React from "react"
import { useContext } from "react"
import { useNavigate, useLocation } from "react-router-dom"
import PropTypes from "prop-types"
import { alpha } from "@mui/material/styles"
import Box from "@mui/material/Box"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TablePagination from "@mui/material/TablePagination"
import TableRow from "@mui/material/TableRow"
import TableSortLabel from "@mui/material/TableSortLabel"
import Toolbar from "@mui/material/Toolbar"
import Typography from "@mui/material/Typography"
import Paper from "@mui/material/Paper"
import Checkbox from "@mui/material/Checkbox"
import IconButton from "@mui/material/IconButton"
import Tooltip from "@mui/material/Tooltip"
import FormControlLabel from "@mui/material/FormControlLabel"
import Switch from "@mui/material/Switch"
import DeleteIcon from "@mui/icons-material/Delete"
import FilterListIcon from "@mui/icons-material/FilterList"
import { visuallyHidden } from "@mui/utils"
import { AddCircleRounded } from "@mui/icons-material"
import { NavigationContext } from "../../../context/NavigationContext"
import { TextField } from "@mui/material"
import SearchIcon from "@mui/icons-material/Search"

import { styled } from "@mui/material/styles"

const StyledTextField = styled(TextField)({
  "& .MuiOutlinedInput-input": {
    padding: "4px 8px", // Custom padding
  },
})

const formatDate = (date) => {
  return date.toISOString().split("T")[0] // Converts to "YYYY-MM-DD"
}
export default function EnhancedTableToolbar(props) {
  const { numSelected, title, headCells, onFilterChange } = props
  const navigate = useNavigate()
  const location = useLocation()
  const { selectedItem } = location.state || {}
  const { selection } = useContext(NavigationContext)

  const today = new Date()
  const tomorrow = new Date(today)
  tomorrow.setDate(today.getDate() + 1)
  // Date range state
  const [startDate, setStartDate] = React.useState(
    formatDate(new Date("2020-01-01T00:00:00"))
  )
  const [endDate, setEndDate] = React.useState(formatDate(tomorrow))

  const handleDateChange = (newValue, name) => {
    if (name === "startDate") {
      setStartDate(newValue)
    } else {
      setEndDate(newValue)
    }
  }

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          {title}
        </Typography>
      )}
      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <>
          <StyledTextField
            label="Start Date"
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            InputLabelProps={{ shrink: true, style: { fontSize: "0.8rem" } }}
            sx={{ marginRight: 2, width: 250 }}
          />
          <StyledTextField
            label="End Date"
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            InputLabelProps={{ shrink: true, style: { fontSize: "0.8rem" } }}
            sx={{ marginRight: 2, width: 250 }}
          />
          <IconButton
            onClick={
              () =>
                startDate !== null && endDate !== null
                  ? onFilterChange({ startDate, endDate })
                  : alert("Must Fill Start Date and End Date") // Call parent filter function
            }
          >
            <SearchIcon />
          </IconButton>
          <Tooltip title="Add New">
            <IconButton
              onClick={() =>
                navigate(`/wp-admin/payment/addnew`, {
                  state: {
                    headCells,
                    selectedItem,
                  },
                })
              }
            >
              <AddCircleRounded />
            </IconButton>
          </Tooltip>
        </>
      )}
    </Toolbar>
  )
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
}
