import React, { useState } from "react"
import { Stack, Typography, Button, Box, TextField } from "@mui/material"
import AnimatedCounter from "../../../../components/DistanceCounter"
import LocationField from "../../../../components/LocationField"

const EstimateForm = ({
  pickupValue,
  setPickupValue,
  dropValue,
  setDropValue,
  distance,
  setDistance,
  calculateDistance,
  width = "200px",
  formValues,
  setFormValues,
  estimateCost,
  estimatedCostValue,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: "#fff",
        color: "#000",
        borderRadius: "8px",
        maxHeight: "800px",
        overflowY: "auto",
        // padding: "20px",
        paddingTop: "20px",
      }}
    >
      <Stack direction="column" spacing={2} width="100%">
        <LocationField
          value={pickupValue}
          setValue={setPickupValue}
          label="Pickup Location"
          width={width}
          isPickup={true}
        />
        <LocationField
          value={dropValue}
          setValue={setDropValue}
          label="Drop Location"
          width={width}
          isPickup={false}
        />
        <TextField
          label="Promo Code (Optional)"
          sx={{ width: width }}
          value={formValues.promoCode}
          onChange={(e) =>
            setFormValues({ ...formValues, promoCode: e.target.value })
          }
        />
        {distance && estimatedCostValue && (
          <AnimatedCounter
            targetDistance={distance}
            targetCost={estimatedCostValue}
          />
        )}

        {/* {distance && <AnimatedCounter targetValue={distance} />}
        {estimatedCostValue && <AnimatedCounter targetValue={estimatedCostValue} />} */}
        <Button
          variant="contained"
          sx={{
            marginTop: "20px",
            backgroundColor: "#FFCF43",
            width: { width },
            "&:hover": {
              backgroundColor: "#FFD8A3", // Light yellow color on hover
            },
          }}
          onClick={async () => {
            try {
              await estimateCost({
                origin: pickupValue,
                destination: dropValue,
              })
            } catch (error) {
              console.error("Error calculating distance:", error)
            }
          }}
        >
          Estimate
        </Button>
      </Stack>
    </Box>
  )
}

export default EstimateForm
