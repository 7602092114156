import * as React from "react"
import { useContext } from "react"
import { useNavigate, useLocation } from "react-router-dom"
import PropTypes from "prop-types"
import { alpha } from "@mui/material/styles"
import Toolbar from "@mui/material/Toolbar"
import Typography from "@mui/material/Typography"
import IconButton from "@mui/material/IconButton"
import Tooltip from "@mui/material/Tooltip"
import DeleteIcon from "@mui/icons-material/Delete"
import FilterListIcon from "@mui/icons-material/FilterList"
import { AddCircleRounded } from "@mui/icons-material"
import { NavigationContext } from "../../../context/NavigationContext"

export default function EnhancedTableToolbar(props) {
  const { numSelected, title, headCells } = props
  const navigate = useNavigate()
  const location = useLocation()
  const { selectedItem } = location.state || {}
  const { selection } = useContext(NavigationContext)

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          {title}
        </Typography>
      )}
      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <>
          <Tooltip title="Add New">
            <IconButton
              onClick={() =>
                navigate(`/wp-admin/driver/addnew`, {
                  state: {
                    headCells,
                    selectedItem,
                  },
                })
              }
            >
              <AddCircleRounded />
            </IconButton>
          </Tooltip>
          <Tooltip title="Filter list">
            <IconButton>
              <FilterListIcon />
            </IconButton>
          </Tooltip>
        </>
      )}
    </Toolbar>
  )
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
}
